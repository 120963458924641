import { Injectable } from '@angular/core';


const TOKEN_KEY = 'token';
const USER_ROLE = 'role';


@Injectable({
  providedIn: 'root'
})
export class TokenStorage {

  constructor() { }

  signOut() {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(USER_ROLE);
    window.localStorage.clear();
  }

  public saveToken(token: string) {
    if (!token) {
      return;
    }
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY,  token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveRole(role: string) {
    if (!role) {
      return;
    }
    window.localStorage.removeItem(USER_ROLE);
    window.localStorage.setItem(USER_ROLE,  role);
  }
  public getRole(): string {
    return localStorage.getItem(USER_ROLE);
  }

}


