import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { TokenStorage } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  error: any;
  user: any;
  userData: any = [];
  public $userSource = new Subject<any>();
  baseUrl = environment.apiUrl

  data = {}
  private loginUserData = new BehaviorSubject(this.data);
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    token: this.token.getToken()
});

headers2 = new HttpHeaders({
  'token': this.token.getToken()
});
  currentUser = this.loginUserData.asObservable();


  constructor(private http: HttpClient , private token: TokenStorage) { }

 
  getUserData(data: any) {
    this.loginUserData.next(data)
  }
  mobileLogin(data){
    return this.http.post(this.baseUrl + '/auth/login/mobile',data)
  }
  verifyOtp(mobile_number: string, otp:string): Observable<any> {
    console.log(mobile_number, );
    return Observable.create(observer => {
      this.http.post(this.baseUrl + '/auth/verifyotp/customer', { mobile_number, otp }).subscribe((data: any) => {
        console.log(data);
        this.userData = data;
        console.log(this.userData);
        this.getUserData(this.userData)
        observer.next({ user: data.data.user });
        this.setUser(data.data.user);
        this.token.saveToken(data.data.token);
        observer.complete();
      }, (error: HttpErrorResponse) => {
        console.log(error);
        observer.next({ error });
        observer.complete();
      });
    });
  }

  setUser(user): void {
    this.$userSource.next(user);
    (window as any).user = user;
  }
  signOut(): void {
    this.token.signOut();
    this.setUser(null);
    delete (window as any).user;
    window.open('/', '_self');
  }

  isAuthenticated() {
    if (this.token.getToken()) {
      return true;
    } else {
      return false;
    }
  }
  authUser(){
   return this.http.get(this.baseUrl + '/auth/me',  { headers: this.headers })
  }

  updateUser(data) {
     return this.http.post(this.baseUrl + '/user/update/me',data ,  { headers: this.headers2 })
    }
    isMember() {
      return this.http.get(this.baseUrl + '/membership/purchase',  { headers: this.headers2 })
     }
}
