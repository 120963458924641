<app-header-one *ngIf="!hahaMartFeature" [sticky]="true"></app-header-one>

<!-- <ng-container *ngIf="hahaMartFeature">
    <app-header-four *ngIf="themeLogo"  [themeLogo]="themeLogo"></app-header-four>
</ng-container > -->
<ng-container *ngIf="hahaMartFeature">
    <div class="header-container">
    <app-bigbasket-header *ngIf="themeLogo"  [themeLogo]="themeLogo" ></app-bigbasket-header>
    </div>
</ng-container>


<router-outlet></router-outlet>
<app-footer-one></app-footer-one>
