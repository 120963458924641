<ng-template class="theme-modal" #subscribeModal let-modal>
    <!-- <div class="container ">
           
             -->
    <div class="modal-content quick-view-modal crad">
        <div class="model-header card-header">
            <h3>Subscribe Product</h3>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="card">
                    <div class="row">
                        <div class="col-md-10 mr-10">
                            <div class="alert alert-success" role="alert">
                                Subscribe <strong>{{selectedProductName|titlecase}}
                                    <span *ngFor="let att of selctedVarientProduct[0]?.attributes">
                                        {{att?.option?.value}}
                                    </span>
                                </strong>
                            </div>
                        </div>

                       
                        <ng-container *ngIf="showAddressList===false">
                            <div class="col-md-12">
                                <a class="pd-10" *ngIf="!selectedAddress" href="javascript:void(0)" (click)="showAddress()">Select Deliver Address</a>
                                <a class="pd-10" *ngIf="selectedAddress" href="javascript:void(0)" (click)="showAddress()">Change Deliver Address</a>
                        </div>
                    </ng-container>
                         <ng-container *ngIf="showAddressList===true">
                            <div class="col-md-12">
                            <a class="pd-10" href="javascript:void(0)" (click)="showAddress()">Hide Deliver Address</a>
                        <br>
                        </div>
                        </ng-container>
                        <ng-container *ngIf="showAddressList===true"> 
                        <div class="col-md-5 m-auto" *ngFor="let address of allAddress;let i=index">
                            <div class="card bg-light mb-3"> 
                               
                                <div class="card-body">
                                    <h5 class="card-title">{{address.name|titlecase}}</h5>
                                    <p class="card-text">{{address.mobile_number}}</p>
                                    <p class="card-text">
                                        {{address?.addressLine1|titlecase}}&nbsp;,{{address?.addressLine2|titlecase}}</p>
                                    <p class="card-text">
                                        {{address?.city|titlecase}}&nbsp;{{address?.state|titlecase}}&nbsp;{{address?.pincode}}
                                    </p>
                                    <div class="text-center">
                                        <button (click)="toggleAddress(i)"
                                        class="btn-solid btn address-btn">Deliver Here</button>
                                
                                    </div>
                                </div>
                                <br>
                            </div>
                        </div>
                       
                    </ng-container>
                    <div class="col-md-6 mr-10" *ngIf="selectedAddress" >
                        <div class="card bg-light mb-3">
                            <div class="card-body">
                                <h5 class="card-title">{{selectedAddress?.name|titlecase}}</h5>
                                <p class="card-text">{{selectedAddress?.mobile_number}}</p>
                                <p class="card-text">
                                    {{selectedAddress?.addressLine1|titlecase}}&nbsp;,{{selectedAddress?.addressLine2|titlecase}}</p>
                                <p class="card-text">
                                    {{selectedAddress?.city|titlecase}}&nbsp;{{selectedAddress?.state|titlecase}}&nbsp;{{selectedAddress?.pincode}}
                                </p>
                                <div class="alert alert-primary" role="alert">
                                    Selected  Deliver Address
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-box">
                            <div class="container">
                                <div class="row">
                                <div class="col-quantity">
                                    <div class="divider-text">Sunday </div>
                                    <div class="quantity">
                                        <a  (click)="sundayDecrease()" class="quantity__minus"><span>-</span></a>
                                        <input [(ngModel)]="sun" name="quantity"  type="text" class="quantity__input" value="1">
                                        <a  (click)="sundayIncrease()" class="quantity__plus"><span>+</span></a>
                                      </div>
                                </div>
                                <div class="col-quantity">
                                    <div class="divider-text">Monday </div>
                                    <div class="quantity">
                                        <a  (click)="mondayDecrease()" class="quantity__minus"><span>-</span></a>
                                        <input [(ngModel)]="mon" name="quantity"  type="text" class="quantity__input" value="1">
                                        <a  (click)="mondayIncrease()" class="quantity__plus"><span>+</span></a>
                                      </div>
                                </div>
                                <div class="col-quantity">
                                    <div class="divider-text">Tuesday </div>
                                    <div class="quantity">
                                        <a  (click)="tuesdayDecrease()" class="quantity__minus"><span>-</span></a>
                                        <input [(ngModel)]="tue" name="quantity"  type="text" class="quantity__input" value="1">
                                        <a  (click)="tuesdayIncrease()" class="quantity__plus"><span>+</span></a>
                                      </div>
                                </div>
                                <div class="col-quantity">
                                    <div class="divider-text">Wednesday </div>
                                    <div class="quantity">
                                        <a  (click)="wednesdayDecrease()" class="quantity__minus"><span>-</span></a>
                                        <input [(ngModel)]="wed" name="quantity"  type="text" class="quantity__input" value="1">
                                        <a  (click)="wednesdayIncrease()" class="quantity__plus"><span>+</span></a>
                                      </div>
                                </div>
                                <div class="col-quantity">
                                    <div class="divider-text">Thrusday </div>
                                    <div class="quantity">
                                        <a  (click)="thrusdayDecrease()" class="quantity__minus"><span>-</span></a>
                                        <input [(ngModel)]="thr" name="quantity"  type="text" class="quantity__input" value="1">
                                        <a  (click)="thrusdayIncrease()" class="quantity__plus"><span>+</span></a>
                                      </div>
                                </div>
                                <div class="col-quantity">
                                    <div class="divider-text">Friday </div>
                                    <div class="quantity">
                                        <a  (click)="fridayDecrease()" class="quantity__minus"><span>-</span></a>
                                        <input [(ngModel)]="fri" name="quantity"  type="text" class="quantity__input" value="1">
                                        <a  (click)="fridayIncrease()" class="quantity__plus"><span>+</span></a>
                                      </div>
                                </div>
                                <div class="col-quantity">
                                    <div class="divider-text">Saturday </div>
                                    <div class="quantity">
                                        <a  (click)="saturdayDecrease()" class="quantity__minus"><span>-</span></a>
                                        <input [(ngModel)]="sat" name="quantity"  type="text" class="quantity__input" value="1">
                                        <a  (click)="saturdayIncrease()" class="quantity__plus"><span>+</span></a>
                                      </div>
                                </div>
                            </div>
                                
                              </div>
                           </div>
                    </div>
                    <br>
                    <div class="col-md-12 mr-tb-10 text-center">
                        <br><br>
                        <button type="submit" (click)="onSubmit()" class="btn-solid btn"> Submit</button>
                        <br>
                    </div>
                </div>
                </div>
            </div>
            <div class="checkout-page" *ngIf="enableAddress">
                <div class="checkout-form">
                    <form [formGroup]="checkoutForm" (ngSubmit)="submitAddress()">
                        <div class="row check-out">
                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                <div class="field-label">Name</div>
                                <input type="text" name="name" [formControl]="checkoutForm.controls['name']" value=""
                                    placeholder="" autocomplete="off">
                                <div *ngIf="checkoutForm.controls.name.touched && checkoutForm.controls.name.errors?.required"
                                    class="text text-danger">
                                    Name is required.
                                </div>
                                <div *ngIf="checkoutForm.controls.name.touched && checkoutForm.controls.name.errors?.pattern"
                                    class="text text-danger">
                                    Name must be an alphabates.
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                <div class="field-label">Mobile Number</div>
                                <input type="text" name="mobile_number"
                                    [formControl]="checkoutForm.controls['mobile_number']" value="" placeholder=""
                                    autocomplete="off">
                                <div *ngIf="checkoutForm.controls.mobile_number.touched && checkoutForm.controls.mobile_number.errors?.required"
                                    class="text text-danger">
                                    Mobile Number No is required.
                                </div>
                                <div *ngIf="checkoutForm.controls.mobile_number.touched && checkoutForm.controls.mobile_number.errors?.pattern"
                                    class="text text-danger">
                                    Mobile Number No is must be number.
                                </div>
                            </div>

                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <div class="field-label">Address</div>
                                <input type="text" name="addressOne"
                                    [formControl]="checkoutForm.controls['addressLine1']" value="" placeholder=""
                                    autocomplete="off">
                                <div *ngIf="checkoutForm.controls.addressLine1.touched && checkoutForm.controls.addressLine1.errors?.required"
                                    class="text text-danger">
                                    Address is required
                                </div>
                                <div *ngIf="checkoutForm.controls.addressLine1.touched && checkoutForm.controls.addressLine1.errors?.maxlength"
                                    class="text text-danger">
                                    Maximum 50 character
                                </div>
                            </div>

                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <div class="field-label">Landmark</div>
                                <input type="text" name="address" [formControl]="checkoutForm.controls['addressLine2']"
                                    value="" placeholder="" autocomplete="off">

                            </div>
                            <div class="form-group col-md-4 col-sm-12 col-xs-12">
                                <div class="field-label">City</div>
                                <input type="text" name="city" [formControl]="checkoutForm.controls['city']" value=""
                                    placeholder="" autocomplete="off">
                                <div *ngIf="checkoutForm.controls.city.touched && checkoutForm.controls.city.errors?.required"
                                    class="text text-danger">
                                    City is required
                                </div>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <div class="field-label">State</div>
                                <input type="text" name="state" [formControl]="checkoutForm.controls['state']" value=""
                                    placeholder="" autocomplete="off">
                                <div *ngIf="checkoutForm.controls.state.touched && checkoutForm.controls.state.errors?.required"
                                    class="text text-danger">
                                    State is required
                                </div>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <div class="field-label">Pin Code</div>
                                <input type="text" name="pincode" [formControl]="checkoutForm.controls['pincode']"
                                    value="" placeholder="" autocomplete="off">
                                <div *ngIf="checkoutForm.controls.pincode.touched && checkoutForm.controls.pincode.errors?.required"
                                    class="text text-danger">
                                    Pin Code is required
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn-solid btn"> Submit</button>
                    </form>


                </div>
            </div>
        </div>
    </div>
    <!-- </div>
        </div> -->
</ng-template>