<ng-template class="theme-modal" #sizeChart let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Add Address for</h4>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		  </button>
	  </div>
	<div class="modal-content">
	  <div class="modal-body">
            <div class="container checkout-page">
				<ng-container *ngIf="mapStatus">
              <agm-map [(latitude)]="location.lat" [(longitude)]="location.lng" [(zoom)]="location.zoom" [disableDefaultUI]="true" [zoomControl]="true" [(fitBounds)]='location.viewport'>
                <agm-marker [(latitude)]="location.marker.lat" [(longitude)]="location.marker.lng" 
                [markerDraggable]="location.marker.draggable" (dragEnd)='markerDragEnd($event,{})'></agm-marker>
              </agm-map>     
			</ng-container>
			<a *ngIf="!mapStatus" class="location-a" href="javascript:void(0)" (click)="toggleMapStatus()">
			Pick Address From Map	<i class="fa fa-map-marker" aria-hidden="true"></i>
			</a> 
			<a class="location-a" href="javascript:void(0)" (click)="myLocation()">
				Pick Current Location	<i class="fa fa-map-marker" aria-hidden="true"></i>
				</a> 
			<a *ngIf="mapStatus" class="location-a" href="javascript:void(0)" (click)="toggleMapStatus()">
				Hide Map	<i class="fa fa-map-marker" aria-hidden="true"></i>
				</a> 
				
			  <div class="checkout-form ">
			<form [formGroup]="checkoutForm" (ngSubmit)="submitAddress()">
				<div class="row check-out">
					<div class="form-group col-md-6 col-sm-6 col-xs-12">
						<!-- <div class="field-label">Name</div> -->
						<input type="text" name="name" 
							[formControl]="checkoutForm.controls['name']" value=""
							placeholder="Name" autocomplete="off">
						<div *ngIf="checkoutForm.controls.name.touched && checkoutForm.controls.name.errors?.required"
							class="text text-danger">
							Name is required.
						</div>
						<div *ngIf="checkoutForm.controls.name.touched && checkoutForm.controls.name.errors?.pattern"
							class="text text-danger">
							Name must be an alphabates.
						</div>
					</div>
					<div class="form-group col-md-6 col-sm-6 col-xs-12">
						<!-- <div class="field-label">Mobile Number</div> -->
						<input type="text" name="mobile_number"
							[formControl]="checkoutForm.controls['mobile_number']" value=""
							placeholder="Mobile Number" autocomplete="off">
						<div *ngIf="checkoutForm.controls.mobile_number.touched && checkoutForm.controls.mobile_number.errors?.required"
							class="text text-danger">
							Mobile Number No is required.
						</div>
						<div *ngIf="checkoutForm.controls.mobile_number.touched && checkoutForm.controls.mobile_number.errors?.pattern"
							class="text text-danger">
							Mobile Number No is must be number.
						</div>
					</div>

					<div class="form-group col-md-6 col-sm-12 col-xs-12">
						<!-- <div class="field-label">Address</div> -->
						<input type="text" name="addressOne"
							[formControl]="checkoutForm.controls['addressLine1']" value=""
							placeholder="Address" autocomplete="off">
						<div *ngIf="checkoutForm.controls.addressLine1.touched && checkoutForm.controls.addressLine1.errors?.required"
							class="text text-danger">
							Address is required
						</div>
						<div *ngIf="checkoutForm.controls.addressLine1.touched && checkoutForm.controls.addressLine1.errors?.maxlength"
							class="text text-danger">
							Maximum 50 character
						</div>
					</div>

					<div class="form-group col-md-6 col-sm-12 col-xs-12">
						<!-- <div class="field-label">Landmark</div> -->
						<input type="text" name="address"
							[formControl]="checkoutForm.controls['addressLine2']" value=""
							placeholder="Landmark" autocomplete="off">

					</div>
					<div class="form-group col-md-4 col-sm-12 col-xs-12">
						<!-- <div class="field-label">City</div> -->
						<input type="text" name="city"
							[formControl]="checkoutForm.controls['city']" value=""
							placeholder="City" autocomplete="off">
						<div *ngIf="checkoutForm.controls.city.touched && checkoutForm.controls.city.errors?.required"
							class="text text-danger">
							City is required
						</div>
					</div>
					<div class="form-group col-md-4 col-sm-6 col-xs-12">
						<!-- <div class="field-label">State</div> -->
						<input type="text" name="state"
							[formControl]="checkoutForm.controls['state']" value=""
							placeholder="State" autocomplete="off">
						<div *ngIf="checkoutForm.controls.state.touched && checkoutForm.controls.state.errors?.required"
							class="text text-danger">
							State is required
						</div>
					</div>
					<div class="form-group col-md-4 col-sm-6 col-xs-12">
						<!-- <div class="field-label">Pin Code</div> -->
						<input type="text" name="pincode"
							[formControl]="checkoutForm.controls['pincode']" value=""
							placeholder="Pin Code" autocomplete="off">
						<div *ngIf="checkoutForm.controls.pincode.touched && checkoutForm.controls.pincode.errors?.required"
							class="text text-danger">
							Pin Code is required
						</div>
					</div>
				</div>
				<button type="submit" class="btn-solid btn"> Submit</button>
			</form>
			</div>
		</div>
	   </div>
	</div>
</ng-template>