<div class="main-navbar">
    <div id="mainnav">
        <div class="toggle-nav" [ngStyle]="{ 'padding': sagarFeature ? '15px' : '25' }" (click)="mainMenuToggle()">
            <i class="fa fa-bars sidebar-bar"></i>
        </div>
        <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle" 
        [ngClass]="{'nav-menu-hahamart':hahaMartFeature}" >

            <li class="back-btn">
                <div class="mobile-back text-right" (click)="mainMenuToggle()">
                    <span>Back</span>
                    <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
            </li>
            <li [ngClass]="{'desk-none':hahaMartFeature}">
                <a href="javascript:void(0)" class="nav-link" routerLink="">Home </a>
            </li>
            <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu"
            [ngClass]="{'nav-menu-hahamart-bg':hahaMartFeature}">
                <!-- Sub -->
                <a href="javascript:void(0)" class="nav-link" (click)="toggletNavActive(menuItem)">
                    {{ menuItem.title | translate }}
                    <!-- <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div> -->
                    <span class="sub-arrow" *ngIf="menuItem.Category"></span>
                </a>

                <!-- Link -->
                <!-- <a [routerLink]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link" *ngIf="menuItem.type === 'link'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a> -->
                <!-- External Link -->
                <!-- <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a> -->
                <!-- External Tab Link -->
                <!-- <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a> -->

                <!-- 2nd Level Menu -->
                <ul class="nav-submenu" [class.opensubmenu]="menuItem.active">
                    <li *ngFor="let childrenItem of allCategory |slice:0:11; let i = index"
                    (mouseover)="getAllSubCategory(childrenItem._id)">
                        <!-- Sub -->
                        <!-- <a [routerLink]="['/product/category', childrenItem._id]" -->
                            <!-- <img *ngIf="childrenItem.image" src="{{imageUrl}}/{{childrenItem.image}}" alt="category icon" class="avatar"> -->

                          <a  href="javascript:void(0)"
                          
                          [routerLink]="['/product/category', childrenItem._id]"
                            [queryParams]="{ category: childrenItem.name}">
                            {{ childrenItem.name | translate|slice:0:25 }}
                            <!-- <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span> -->
                            <span class="sub-arrow" *ngIf="allCategory[i]?.subcategory?.length"
                            ></span>
                        </a>
                        <!-- Link -->
                        <!-- <a [routerLink]="childrenItem.name">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.subcategory.length > 0"></span>
            </a> -->
                        <!-- External Link -->
                        <!-- <a href="{{ childrenItem.path }}" *ngIf="childrenItem.type === 'extLink' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a> -->
                        <!-- External Tab Link -->
                        <!-- <a href="{{ childrenItem.path }}" target="_blank" *ngIf="childrenItem.type === 'extTabLink' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a> -->

                        <!-- 3rd Level Menu -->
                        <ul [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu"
                            *ngIf="allCategory[i]?.subcategory?.length">
                            <li *ngFor="let childrenSubItem of allSubcategory"
                            >
                                <!-- <img *ngIf="childrenItem.image" src="{{imageUrl}}/{{childrenSubItem.image}}" alt="category icon" class="avatar"> -->
                                <!-- Link -->
                                <a href="javascript:void(0)"
                                [routerLink]="['/product/category', childrenSubItem._id]"
                                    [queryParams]="{ category: childrenSubItem.name}">
                                    {{ childrenSubItem.name | translate |slice:0:15}}
                                    <!-- <span class="new-tag" >{{ childrenSubItem.badgeText | translate }}</span> -->
                                </a>
                                <!-- External Link -->
                                <!-- <a href="{{ childrenSubItem.path }}" >
                  {{ childrenSubItem.name | translate }}
                  <span class="new-tag">{{ childrenSubItem.badgeText | translate }}</span>
                </a> -->
                                <!-- External Tab Link -->
                                <!-- <a href="{{ childrenSubItem.path }}" target="_blank" >
                  {{ childrenSubItem.name | translate }}
                  <span class="new-tag">{{ childrenSubItem.badgeText | translate }}</span>
                </a> -->
                            </li>
                        </ul>
                    </li>
                </ul>

                <div class="mega-menu-container d-none" *ngIf="menuItem.megaMenu && menuItem.Category ">
                    <div class="container">
                        <div class="row">
                            <div class="col mega-box" *ngFor="let childrenItem of menuItem.Category ">
                                <div class="link-section">
                                    <div class="menu-title">
                                        <h5>
                                            {{ childrenItem.title | translate }}
                                            <span class="sub-arrow" *ngIf="childrenItem.Category"></span>
                                        </h5>
                                    </div>
                                    <div class="menu-content opensubmegamenu">
                                        <ul *ngIf="childrenItem.Category ">
                                            <li *ngFor="let childrenSubItem of childrenItem.Category ">
                                                <!-- Sub -->
                                                <a href="javascript:void(0)" *ngIf="childrenSubItem.type === 'sub'">
                                                    {{ childrenSubItem.title | translate }}
                                                    <span class="new-tag" *ngIf="childrenSubItem.badge">{{
                                                        childrenSubItem.badgeText | translate }}</span>
                                                </a>
                                                <!-- Link -->
                                                <a [routerLink]="childrenSubItem.path" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}"
                                                    *ngIf="childrenSubItem.type === 'link' ">
                                                    {{ childrenSubItem.title | translate }}
                                                    <span class="new-tag" *ngIf="childrenSubItem.badge">{{
                                                        childrenSubItem.badgeText | translate }}</span>
                                                </a>
                                                <!-- External Link -->
                                                <a href="{{ childrenSubItem.path }}"
                                                    *ngIf="childrenSubItem.type === 'extLink' ">
                                                    {{ childrenSubItem.title | translate }}
                                                    <span class="new-tag" *ngIf="childrenSubItem.badge">{{
                                                        childrenSubItem.badgeText | translate }}</span>
                                                </a>
                                                <!-- External Tab Link -->
                                                <a href="{{ childrenSubItem.path }}" target="_blank"
                                                    *ngIf="childrenSubItem.type === 'extTabLink' ">
                                                    {{ childrenSubItem.title | translate }}
                                                    <span class="new-tag" *ngIf="childrenSubItem.badge">{{
                                                        childrenSubItem.badgeText | translate }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            
            <li [ngClass]="{'desk-none':hahaMartFeature}">
                <a href="javascript:void(0)" class="nav-link" routerLink="/pages/aboutus"> About Us </a>
            </li>


            <li class="desk-none">
                <a href="javascript:void(0)" class="nav-link" routerLink="/pages/faq"> FAQ </a>
            </li>

            <li class="desk-none">
                <a href="javascript:void(0)" class="nav-link" routerLink="/pages/privacy-policy"> Privacy Policy </a>
            </li>

            <li class="desk-none">
                <a href="javascript:void(0)" class="nav-link" routerLink="/pages/return-policy"> Return Policy </a>
            </li>

            <li class="desk-none" *ngIf="sellerPolicy">
                <a href="javascript:void(0)" class="nav-link" routerLink="/pages/seller-policy"> Seller Policy </a>
            </li>

            <li class="desk-none">
                <a href="javascript:void(0)" class="nav-link" routerLink="/pages/term-and-condition"> Terms and Condition </a>
            </li>

            <li [ngClass]="{'desk-none':hahaMartFeature}" *ngIf="!authService?.isAuthenticated()">
                <a href="javascript:void(0)" class="nav-link" (click)="openlLogin()"> Login </a>
            </li>

            <li [ngClass]="{'desk-none':hahaMartFeature}" *ngIf="authService?.isAuthenticated()" >
                <!-- Sub -->
                <a href="javascript:void(0)" class="nav-link" (click)="toggleSumMenu()" >
                    My Account
                    <span class="sub-arrow" ></span>
                </a>
                <!-- 2nd Level Menu -->
                <ul  class="nav-submenu " 
                [ngClass]="{
                    'opensubmenu':isFavirote
                   }" >
                    <li> <a [routerLink]="['/product/order-list']" href="javascript:void(0)">
                        Orders
                    </a> </li>
                    <li> <a [routerLink]="['/pages/dashboard']" href="javascript:void(0)">
                        Dashboard
                    </a> </li>
                   
                    <li > 
                        <a (click)="authService?.signOut()" href="javascript:void(0)">
                            Logout
                        </a> 
                    </li>
                </ul>

            </li>
        </ul>
    </div>
</div>
<app-login-modal #login></app-login-modal>
