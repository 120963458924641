import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-top-category',
  templateUrl: './top-category.component.html',
  styleUrls: ['./top-category.component.scss']
})
export class TopCategoryComponent implements OnInit {
  imageUrl = environment.img
  kudlaFeature = environment.kudlaFeature
  @Input() allCategory
  constructor() { }

  ngOnInit(): void {
  }

}
