<div class="container category-button">
    <!-- <section class="section-b-space"> -->
        <div class="row" >
            <div class="col" >

                <div class="slide-6 arrow " >
                    <owl-carousel-o [options]="SubCategorySlider">
                        <ng-template class="card" carouselSlide *ngFor="let b of allSubcategory" >
                            <div  class="category-block mr-2" (click)="addNewItem(b._id)" 
                            
                            [routerLink]="['/product/category',b._id]" [queryParams]="{ category: b.name}" >
                            <div class="widt">
                                <a >
                                    <div [ngClass]="{'subcat-active':b._id == categoryId}" class="category-image">
                                        <img src="{{imageUrl}}/{{b?.image}}" alt="" class=" category-logo-cc">
                                    </div>
                                </a>
                                <div class="category-details " [routerLink]="['/product/category',b._id]" [queryParams]="{ category: b.name}">
                                    <a >
                                        <h5 [ngClass]="{'subcat-active-text':b._id == categoryId}" class="category-name">{{b?.name |titlecase}}</h5>
                                    </a>
                                </div>
                            </div>
                              
                            </div>
                        
                            <!-- <div class="container category-button">
                                <section class="section-b-space">
                                    <div class="row partition1">
                                        <div class="col"><a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}" class="btn btn-outline btn-block">{{b?.name |titlecase}}</a></div>
                        
                                    </div>
                                </section>
                            </div> -->
                        </ng-template>
                        <!-- [ngClass]="{'border-yellow':selectedColor == 'yellow'}" -->
                        <!-- <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat2.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>casual shoes</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat3.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>formal shoes</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat4.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>flat</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat5.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>heels</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat6.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>boots</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat2.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>casual shoes</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat3.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>casual shoes</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template> -->
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    <!-- </section> -->
</div>