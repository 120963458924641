import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { ResponseModel } from '../classes/response.model';
import { AuthService } from './auth.service';
import { TokenStorage } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    //  token: this.tokenService.getToken()
  });
  allCategoryData$
  allCategoryDataByLocation$
  aboutUsData$
  baseUrl = environment.apiUrl
  headersAuth = new HttpHeaders({
    'Content-Type': 'application/json',
    token: this.token.getToken()
  });
  pincode
  constructor(private http: HttpClient, private authService: AuthService, private token: TokenStorage) {
    this.pincode = localStorage.getItem('pincode')
    let pinparams
    if(this.pincode) pinparams="?pincode="+this.pincode
    if(!this.pincode) pinparams=""

    this.getAllCategory()
    this.getAllCategoryByLOcation(pinparams)
    this.getAbouUs()
   

   }

  getAllBanners(): Observable<any> {
    return this.http.get(this.baseUrl + '/banner');
  }

  getAllBrand(): Observable<any> {
    return this.http.get(this.baseUrl + '/product/brand');
  }
 
  getAllCategory(){
    this.allCategoryData$ = this.http.get(this.baseUrl + '/product/category/all/product').pipe(
      tap(()=>console.log("Http request ")),
      map((res:any)=> Object.values(res.data)),
      shareReplay()
      )
  }
  getAllCategoryByLOcation(params){
    this.allCategoryDataByLocation$= this.http.get(this.baseUrl + '/product/category/all/product' + params).pipe(
      tap(()=>console.log("Http request ")),
      map((res:any)=> Object.values(res.data)),
      shareReplay()
      );
  }

  getAbouUs() {
  this.aboutUsData$= this.http.get(this.baseUrl + '/aboutus').pipe(
    tap(()=>console.log("Http request for about us")),
    // map((res:any)=> Object.values(res.)),
    shareReplay(1)
    );
  }

  getFeatureCategory(): Observable<any> {
    return this.http.get(this.baseUrl + '/product/category/feature');
  }
  getSecondLevelBanners() {
    return this.http.get(this.baseUrl + '/banner/slider2');
  }
  getThirdLevelBanners() {
    return this.http.get(this.baseUrl + '/banner/slider3');
  }
  
 
  getAllFeatureProduct() {
    if (this.authService.isAuthenticated()) {
      return this.http.get(this.baseUrl + '/product/feature/app', { headers: this.headersAuth })
    } else {
      return this.http.get(this.baseUrl + '/product/feature/app')

    }
  }

  getBestSellingProduct() {
    return this.http.get(this.baseUrl + '/product/varients/trending')
  }
  getAllFlashBanners(): Observable<any> {
    return this.http.get(this.baseUrl + '/flashbanner');

  }


  getFaq(): Observable<any> {
    return this.http.get(this.baseUrl + '/faq');
  }

  getPolicy(): Observable<any> {
    return this.http.get(this.baseUrl + '/privacypolicy');
  }

  getSellerPolicy(): Observable<any> {
    return this.http.get(this.baseUrl + "/sellerpolicy");
  }

  getReturnPolicy(): Observable<any> {
    return this.http.get(this.baseUrl + "/returnpolicy");
  }

  getAdminPost(): Observable<any> {
    return this.http.get(this.baseUrl + "/admin/post");
  }
  getAdminReview(): Observable<any> {
    return this.http.get(this.baseUrl + "/admin/review");
  }
  getTermAndCondition(): Observable<any> {
    return this.http.get(this.baseUrl + "/tnc");
  }

  getWallaetBalanceHistory(params){
    return this.http.get(this.baseUrl + "/payment"+params,{ headers: this.headersAuth });
  }
  addWallaetBalance(data){
    return this.http.post(this.baseUrl + "/payment/walletrecharge", data, { headers: this.headersAuth });
  }
}
