<ng-template class="theme-modal" #sizeChart let-modal>
  <div class="modal-content">
    <div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <section>
        <div class="container">
          <div class="left">
            <div class="header">
              <h2 *ngIf="title" class="animation a1">Welcome To {{ title }}</h2>
              <h4 class="animation a2">
                Login to access your Orders, Cart Items and Wishlist.
              </h4>
            </div>
            <div class="form">
              <div class="form-group">
                <input
                  type="text"
                  [(ngModel)]="mobile_number"
                  [ngModelOptions]="{ standalone: true }"
                  id="mobile"
                  placeholder="Mobile Number"
                  class="form-field form-control animation a3"
                />
              </div>
              <div class="form-group">
                <input
                  *ngIf="sendOtpEnable"
                  type="password"
                  class="form-field animation a4 form-control"
                  type="text"
                  [(ngModel)]="otp"
                  [ngModelOptions]="{ standalone: true }"
                  id="review"
                  placeholder="OTP"
                />
              </div>
              <!-- <p class="animation a5"><a href="#">Forgot Password</a></p> -->
              <button
                *ngIf="!sendOtpEnable"
                (click)="sendOtp()"
                class="animation a6"
              >
                Send OTP
              </button>
              <button
                *ngIf="sendOtpEnable"
                (click)="sendVarifyOtp()"
                class="animation a6"
              >
                Verify OTP
              </button>
            </div>
          </div>
          <div class="right"></div>
        </div>
      </section>
    </div>
  </div>
</ng-template>
