<div class="icon-nav">
  <ul class="custom-flex">
    <li class="onhover-div mobile-setting home-icon">
      <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
        <i [routerLink]="['/home']"  class="ti-home"></i></div>
    </li>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div routerLink="/product/search" >
        <img src="assets/images/icon/search.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
      </div>

    </li>
    
   
    <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/product/cart']">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ products?.length }}</span>

    </li>
  </ul>
</div>


<!-- <div class="icon-nav">
  <ul class="custom-flex">
    <li class="onhover-div mobile-setting home-icon">
      <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
        <i [routerLink]="['/home']"  class="ti-home"></i></div>
    </li>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <img src="assets/images/icon/search.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
      </div>
      <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
        <div> 
            <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
            <div class="overlay-content">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputPassword1"
                                    [(ngModel)]="productName" [ngModelOptions]="{standalone: true}" (ngModelChange)="searchProduct()" 
                                    placeholder="Search a Product">
                                </div>
                                <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                            </form>
                        </div>
                        <div class="col-sm-12 text-center" *ngIf="!allProducts.length">
                          <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                            <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
                            <p>Please check if you have misspelt something or try searching with other words.</p>
                            <a [routerLink]="['/shop/collection/left/sidebar']" class="btn btn-solid">continue shopping</a>
                        </div>
                        <div class="col-sm-12"  *ngIf="allProducts.length!=0">
                            <div class="collection-product-wrapper" id="products">
                                                   
                                <div class="product-wrapper-grid" [ngClass]="layoutView">
                                    <div class="row">
                                        <div class="col-grid-box card"
                                            [ngClass]="grid"
                                            *ngFor="let product of allProducts">
                                            <div class="product-box">
                                                <app-product-box-one 
                                                    [product]="product" 
                                                    [currency]="productService?.Currency"
                                                    [thumbnail]="true" 
                                                    >
                                                </app-product-box-one>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </li>
  
    <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/product/cart']">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ products?.length }}</span>

    </li>
  </ul>
</div> -->













