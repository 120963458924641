import { Component, PLATFORM_ID, Inject,OnInit, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Title, } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';
import { DOCUMENT } from '@angular/common';
import { AnalyticsTagService } from './shared/services/analytics-tag.service';
import { MainService } from './shared/services/main.service';
import { ResponseModel } from './shared/classes/response.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  title :any;
 logo
 logoUrl
 imageUrl=environment.img
 aboutUs
 aboutUsInComponenet$
  constructor(@Inject(PLATFORM_ID) private platformId: Object,translate: TranslateService,
    private loader: LoadingBarService,private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private titleService: Title ,private googleAnalyticsService: AnalyticsTagService,
    private mainService:MainService){

    //   let script = this._renderer2.createElement('script');
    //   script.type = `application/json`;
    //   script.async =`sync`
    //   script.src = `https://www.googletagmanager.com/gtag/js?id='`+environment.gu+ `'`;
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = `application/json`;
    //   script2.text = `
    //   {
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag(){dataLayer.push(arguments);}
    //   gtag('js', new Date());
    
    //   gtag('config', '`+environment.gu+ `');
    // }`;
     
      // this._renderer2.appendChild(this._document.head, script);
      // this._renderer2.appendChild(this._document.head, script2);

      // this.appendGaTrackingCode();
  
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
  }
  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
       
        ga('create', 'AW-969874463', 'auto');
      `;
      document.head.appendChild(script);
    } catch (ex) {
     console.error('Error appending google analytics');
     console.error(ex);
    }
  }

  ngOnInit() {
    this.aboutUsInComponenet$ = this.mainService.aboutUsData$
    this.titleService.setTitle(this.title);
    this.changeIcon()
    //this.gta.pageView('/home', 'Teste de Title')
    //this.googleAnalyticsService.emitEvent("Page View", "Home", );
    this.getAbouUs()
  }
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  changeIcon() {
    this.favIcon.href = this.logoUrl;  
}
getAbouUs() {
  this.aboutUsInComponenet$.subscribe((res:ResponseModel) => {
    this.aboutUs = res.data;
    this.logoUrl = this.imageUrl +"/"+this.aboutUs.logo
    this.changeIcon()
  });

  
}
}
