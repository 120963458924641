import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb3',
  templateUrl: './breadcrumb3.component.html',
  styleUrls: ['./breadcrumb3.component.scss']
})
export class Breadcrumb3Component implements OnInit {

  @Input() title : string;
  @Input() breadcrumb : any;

  constructor() {
  }

  ngOnInit() : void { 
    console.log(this.breadcrumb)
   }

}
