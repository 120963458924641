export const environment = {
    production: true,
    instagram_token: 'INSTA_TOKEN',
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
  
    // LOCALHOST URL
  
   // apiUrl : "http://localhost:5000/api",
   // apiUrl: "http://3.108.254.228/api/",
    // LIVE URL

    apiUrl: "/api",
  
    //  TEST URL
  
    // apiUrl :"http://65.0.133.91/api", //test
    //apiUrl :"http://13.126.125.5/api", //shivmindful
    //apiUrl :"http://13.232.247.227/api", //zweger
    // apiUrl :"https://65.2.158.247/api", //Urban Kirana  
    //apiUrl :"http://13.235.33.174/api", //Alfaz  Mbaniya
    // apiUrl :" http://52.66.240.68/api" ,// Daily Mantra
   // apiUrl :"https://kudla.store/api", //Kudla
    //apiUrl :"http://35.154.162.219/api", // bapu
   //apiUrl :"http://65.0.202.121/api", // dehlyver
    // apiUrl :"http://13.232.64.205/api", // sagar khurana
    // apiUrl :"http://13.233.85.113/api", // pinkesh togani
    // apiUrl :"http://65.0.191.77/api", // Bhavki Amit patkar
    // apiUrl :"http://15.206.27.125/api", // Buddy-Firms
    //apiUrl :"http://65.0.61.103/api", //Befresh
    //apiUrl : "http://65.1.114.147/api", //zwegermindful 
   // apiUrl :"http://13.201.134.236/api",
     // apiUrl :"https://www.wocafu.com/api",
  
    //img:"https://mindfultestbucket.s3.ap-south-1.amazonaws.com",
    //img: "https://zweger.s3.ap-south-1.amazonaws.com",
   //  img:"https://urbankiranamindful.s3.ap-south-1.amazonaws.com",
     img:"https://wocafu2023.s3.ap-south-1.amazonaws.com",
    // img:"https://mbaniya.s3.ap-south-1.amazonaws.com", //Alfaz  Mbaniya
    // img:"https://mandeepmindful.s3.ap-south-1.amazonaws.com",
    //  img:"https://kundlastoremindful.s3.ap-south-1.amazonaws.com", //kundla store
    //img:"https://bapumindful.s3.ap-south-1.amazonaws.com",
    //  img:"https://shivmindful.s3.ap-south-1.amazonaws.com",
    // img:"https://pinkesh.s3.ap-south-1.amazonaws.com",// pinkesh togani
    //img: 'https://dvxo4hhlasv91.cloudfront.net', // Bhavki Amit patkar
    // img:"https://notandmart.s3.ap-south-1.amazonaws.com",\
    // img:"https://umeshmindful.s3.ap-south-1.amazonaws.com", //Buddy-Firms
    // img:"https://befresh.s3.ap-south-1.amazonaws.com", //Befresh
    //img:"https://dehlyver.s3.ap-south-1.amazonaws.com", //dehlyver
    //img:"https://sagarkhurana.s3.ap-south-1.amazonaws.com", //sagar khurana
  
  
  
    //razerpayKey:'rzp_test_6P69vpu1xdzo2e', //Test Key
    //razerpayKey: 'rzp_live_TjfrixqhGFDwX7', //Zweger Live Key
     razerpayKey:'rzp_live_s7PbLdKYLzckDj', //Urban live
    //razerpayKey:"rzp_live_ozSv3EFR0mpBMv", //Kudla live
    // razerpayKey:"rzp_live_9yoNDQtxovdLtT", // pinkesh togani
    selectedPaymentGateway : "razorpay",
    paytm_merchant_Id:'gfdyt5678',
    grannyFeature:true,
    urbanFeature: false,
    toganiFeature: false,
    kudlaFeature: false,
    defaultFeature: true,
    buddy_Firms: false,
    callFeature: false,
    sagarFeature:false,
    hahaMartFeature:false,
    summitFeature:false,
    prakshFeature:false,
    hideOrderId:false,
    categoryBannerOnCategoryPage:false,
    notificationFeature : false,
    ageconfirmation : false,
    payByPaytm : false,
    payByRazor : true,
    zoneWiseDeliveryCharges : true,
    configDeliveryCharges : false,
    topDiscountedProductOnHomePage:false,
    featureCategoryOnHomePage:true,
    topBrand:true,
    onlyProductOnHomePage:true,
    topCategory:true,
    secondryBannerOnHomePage:true,
    featureProductOnHomePage:true,
    firebaseAuthorizationKey:'htrtyuhihuiyut6545347878',
    productSubscription:false,
    adminReview:false,
    adminPost:false,
    sellerPolicy:false,
    shippingScheme : 'shippingScheme',
    paymentModes:[
      { id: 1, value: "Online", name: "Online" },
      { id: 2, value: "COD", name: "COD" },
    ],
    timeSlot:false,
    newTimeSlots:false,
    firebaseToken:''
  
  }