import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title : string;
  @Input() breadcrumb : any;
  sagarFeature = environment.sagarFeature

  constructor() {
  }

  ngOnInit() : void { 
    console.log(this.breadcrumb)
   }
  
}
