import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-tap-to-top',
  templateUrl: './tap-to-top.component.html',
  styleUrls: ['./tap-to-top.component.scss']
})
export class TapToTopComponent implements OnInit {
  
  public show: boolean = false;
  whatsapp
  linkwhatsApp
  callNumber
  mobile
  kudlaFeature = environment.kudlaFeature
  callFeature= environment.callFeature
  constructor(private viewScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.whatsapp = localStorage.getItem('whatsapp')
    this.mobile = localStorage.getItem('mobile')
    this.linkwhatsApp = "//api.whatsapp.com/send?phone=91"+this.whatsapp+"&text=Hello"
    this.callNumber = "tel:"+this.mobile
    console.log(this.linkwhatsApp)
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number > 600) { 
  	  this.show = true;
  	} else {
  	  this.show = false;
  	}
  }

  tapToTop() {
  	this.viewScroller.scrollToPosition([0, 0]);
  }

}
