<!--footer section -->
<footer [class]="class">
    <!-- <div class="light-layout" *ngIf="newsletter">
        <div class="container">
            <section class="small-section border-section border-top-0">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="subscribe">
                            <div>
                                <h4>KNOW IT ALL FIRST!</h4>
                                <p>Never Miss Anything From <span *ngIf="aboutUs?.companyName"><b>{{aboutUs?.companyName}}</b></span> By Signing Up To Our Newsletter. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
                            <div class="form-group mx-sm-3">
                                <input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
                            </div>
                            <button type="submit" class="btn btn-solid">subscribe</button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div> -->
    <section class="section-b-space light-layout">
        <div class="container">
            <div class="row footer-theme partition-f">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-title footer-mobile-title">
                        <h4>about</h4>
                    </div>
                    <div class="footer-contant">
                        <div class="footer-logo"  [ngClass]="{'haha-logo':hahaMartFeature}">
                          <a *ngIf="themeLogo" [routerLink]="['/']">
                            <img class="logo-size" [ngClass]="{'granny-logo':grannyFeature,'prakash-logo':prakshFeature}" *ngIf="themeLogo" [src]="imageUrl+'/'+themeLogo" alt="logo">
                          </a>
                        </div>
                        <!-- <p *ngIf="aboutUs?.paragraph" >{{aboutUs?.paragraph |slice :0:150}} ...</p> -->
                        <div *ngIf="!toganiFeature">
                        <p *ngIf="aboutUs?.paragraph&&!toganiFeature" [innerHTML]="aboutUs?.paragraph.slice(0, 130) "></p>
                        <a style="display: inline;" href="javascript:void(0)" routerLink="/pages/aboutus">...Read more</a>
                    </div>
                    <!-- </ng-container> -->
                    <ng-container *ngIf="!buddy_Firms">
                        <div class="footer-social"> 
                            <ul>
                                <li *ngIf="aboutUs?.facebook">
                                    <a [attr.href]="aboutUs?.facebook" target="_blank">
                                        <i *ngIf="toganiFeature" class="fa fa-facebook" aria-hidden="true"></i>
                                        <img class="icon-size" *ngIf="!toganiFeature" src="../../../../assets/images/facebook.jpg" alt="" srcset="">
                                    </a>
                                </li>
                                <li *ngIf="aboutUs?.instagram">
                                    <a [attr.href]="aboutUs?.instagram" target="_blank">
                                        <i *ngIf="toganiFeature" class="fa fa-instagram" aria-hidden="true"></i>
                                        <img class="icon-size"  *ngIf="!toganiFeature" src="../../../../assets/images/instagram.jpg" alt="" srcset="">
                                    </a>
                                </li>
                                <li *ngIf="aboutUs?.email">
                                    <a [attr.href]="mailto">
                                        <i *ngIf="toganiFeature" class="fa fa-envelope" aria-hidden="true"> </i>
                                        <img *ngIf="!toganiFeature" style="width: 23px;"    src="../../../../assets/images/gmail.png" alt="" srcset="">
                                    </a>
                                </li>
                                <li *ngIf="aboutUs?.youtube">
                                    <a [attr.href]="aboutUs?.youtube" target="_blank">
                                        <i *ngIf="toganiFeature" class="fa fa-youtube-play"  aria-hidden="true"></i>
                                        <img style="width: 40px;"  *ngIf="!toganiFeature" src="../../../../assets/images/youtube.png" alt="" srcset="">

                                    </a>
                                </li>
                                <!-- <li *ngIf="aboutUs?.linkedIn">
                                    <a [attr.href]="aboutUs?.linkedIn" target="_blank">
                                        <i *ngIf="toganiFeature" class="fa fa-linkedin-square" aria-hidden="true"></i>
                                        <img style="width: 40px;"  *ngIf="!toganiFeature" src="../../../../assets/images/youtube.png" alt="" srcset="">

                                    </a>
                                </li> -->
                                <!-- <li *ngIf="aboutUs?.twitter">
                                    <a [attr.href]="aboutUs?.twitter" target="_blank">
                                        <i *ngIf="toganiFeature" class="fa fa-twitter-square"  aria-hidden="true"></i>
                                        <img style="width: 40px;"  *ngIf="!toganiFeature" src="../../../../assets/images/youtube.png" alt="" srcset="">

                                    </a>
                                </li> -->
                                <!-- <li *ngIf="aboutUs?.playStore">
                                    <a [attr.href]="'//' + aboutUs?.playStore" target="_blank"><i class="fa fa-android" aria-hidden="true"></i></a>
                                </li>
                                <li *ngIf="aboutUs?.ioStore">
                                    <a [attr.href]="'//' + aboutUs?.ioStore" target="_blank"><i class="fa fa-apple" aria-hidden="true"></i></a>
                                </li> -->

                            </ul>
                        </div>
                    </ng-container>
                    </div>
                </div>
                <div class="col offset-xl-1">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>Useful Links</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <!-- <li *ngFor="let category of brand |slice:0:5"><a href="javascript:void(0)">
                                    {{category.name}}</a>
                                </li> -->
                                <li>
                                    <a href="javascript:void(0)" routerLink="/pages/aboutus"> About Us </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" routerLink="/pages/faq"> FAQ's </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" routerLink="/pages/term-and-condition"> Terms & Conditions </a>
                                </li>
                    
                                <li>
                                    <a href="javascript:void(0)" routerLink="/pages/privacy-policy"> Privacy Policy </a>
                                </li>
                    
                                <li>
                                    <a href="javascript:void(0)" routerLink="/pages/return-policy"> Return Policy </a>
                                </li>
                    
                                <li *ngIf="sellerPolicy">
                                    <a href="javascript:void(0)" routerLink="/pages/seller-policy"> Seller Policy </a>
                                </li>                                
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>Downloads</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li *ngIf="aboutUs?.playStore">
                                    <a [attr.href]="aboutUs?.playStore" target="_blank" href="javascript:void(0)">
                                    <div class="footer-logo">
                                        <img class="logo-store" src="{{googleLogo}}" alt="logo">
                                    </div>
                                </a></li>
                                <li *ngIf="aboutUs?.ioStore"><a [attr.href]="aboutUs?.ioStore" target="_blank" href="javascript:void(0)">
                                    <div class="footer-logo">
                                        <img class="logo-store" src="{{iosLogo}}" alt="logo">
                                    </div>
                                </a></li>
                                <!-- <li><a href="javascript:void(0)">secure shopping</a></li>
                                <li><a href="javascript:void(0)">gallary</a></li>
                                <li><a href="javascript:void(0)">affiliates</a></li>
                                <li><a href="javascript:void(0)">contacts</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4 *ngIf="(defaultFeature)">store information</h4>
                            <h4 *ngIf="(kudlaFeature)">store information</h4>
                            <h4 *ngIf="(toganiFeature)">Contact Us</h4>
                            <h4 *ngIf="(buddy_Firms)">Contact Us</h4>

                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li><i class="fa fa-map-marker"></i> <span *ngIf="aboutUs?.companyName">{{aboutUs?.companyName}} &nbsp;<br><span *ngIf="aboutUs?.address">{{aboutUs?.address}}</span></span>
                                </li>
                                <li> <i class="fa fa-phone"></i><a [attr.href]="callNumber"><span *ngIf="aboutUs?.mobile">{{aboutUs1}}</span></a></li>
                                <li> <i class="fa fa-envelope-o"></i><a [attr.href]="mailto" ><span *ngIf="aboutUs?.email">{{aboutUs?.email}}</span></a></li>
                                <!-- <li> <span><i class="fas fa-envelope-square" *ngIf="aboutUs"></i> <a [attr.href]="mailto" >{{aboutUs?.email}} </a></span></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p *ngIf="!buddy_Firms"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} {{aboutUs?.companyName}} powered by Mindful Machine Pvt. Ltd.</p>
                        <p *ngIf="buddy_Firms"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} BuddysBasket Trading and Marketing Private Limited</p>
                    </div>
                </div>
                <!-- <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/paypal.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/discover.png" alt=""></a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->