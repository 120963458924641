<!-- header start -->
<header class="sticky" [ngClass]="class">
  <div class="mobile-fix-option"></div>
  <ul class="header-dropdown">
    <!-- <li class="compare">
          <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
        </li> -->
        <li class="mobile-wishlist">
          <a [routerLink]="['/shop/wishlist']"><i class="fa fa-phone" aria-hidden="true">

          </i>Call Us: 123 - 456 - 7890</a>
        </li>
    <li class="onhover-dropdown mobile-account">
      <ng-container *ngIf="authService?.isAuthenticated()"> <i class="fa fa-user" aria-hidden="true"></i> My
        Account</ng-container>
      <ng-container *ngIf="!authService?.isAuthenticated()">
        <a href="javascript:void(0)" (click)="openlLogin()">
          <i class="fa fa-user" aria-hidden="true"></i> Login
        </a>
      </ng-container>
      <ul class="onhover-show-div">
        <li [ngClass]="{'desk-none':hahaMartFeature}" *ngIf="authService?.isAuthenticated()">

          <!-- 2nd Level Menu -->
          <ul class="nav-submenu " [ngClass]="{
            'opensubmenu':isFavirote
           }">
            <li> <a [routerLink]="['/product/order-list']" href="javascript:void(0)">
                Orders
              </a> </li>
            <li> <a [routerLink]="['/pages/dashboard']" href="javascript:void(0)">
                Dashboard
              </a> </li>

            <li>
              <a (click)="authService?.signOut()" href="javascript:void(0)">
                Logout
              </a>
            </li>
          </ul>

        </li>
      </ul>

  </ul>
  <div class="top-header d-none" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <!-- <ul>
              <li>Welcome to Our store Multikart</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 123 - 456 - 7890</li>
            </ul> -->
          </div>
        </div>
        <div class="col-lg-6 text-right">
          <ul class="header-dropdown">
            <!-- <li class="compare">
                  <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
                </li> -->
                <li class="mobile-wishlist">
                  <a [routerLink]="['/shop/wishlist']"><i class="fa fa-phone" aria-hidden="true">

                  </i>Call Us: 123 - 456 - 7890</a>
                </li>
            <li class="onhover-dropdown mobile-account">
              <ng-container *ngIf="authService?.isAuthenticated()"> <i class="fa fa-user" aria-hidden="true"></i> My
                Account</ng-container>
              <ng-container *ngIf="!authService?.isAuthenticated()">
                <a href="javascript:void(0)" (click)="openlLogin()">
                  <i class="fa fa-user" aria-hidden="true"></i> Login
                </a>
              </ng-container>
              <ul class="onhover-show-div">
                <li [ngClass]="{'desk-none':hahaMartFeature}" *ngIf="authService?.isAuthenticated()">

                  <!-- 2nd Level Menu -->
                  <ul class="nav-submenu " [ngClass]="{
                    'opensubmenu':isFavirote
                   }">
                    <li> <a [routerLink]="['/product/order-list']" href="javascript:void(0)">
                        Orders
                      </a> </li>
                    <li> <a [routerLink]="['/pages/dashboard']" href="javascript:void(0)">
                        Dashboard
                      </a> </li>

                    <li>
                      <a (click)="authService?.signOut()" href="javascript:void(0)">
                        Logout
                      </a>
                    </li>
                  </ul>

                </li>
              </ul>

          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu border-section border-top-0">
          <div class="brand-logo layout2-logo">
            <a *ngIf="themeLogo" [routerLink]="['/']">
              <img [src]="themeLogo" class="img-fluid" alt="logo">
            </a>
          </div>
          <div>
            <form class="form_search" role="form">
              <input id="query search-autocomplete" type="search" placeholder="Find the best for your..."
                class="nav-search nav-search-field" aria-expanded="true">
              <button type="button" name="nav-submit-button" class="btn-search">
                <i class="ti-search"></i>
              </button>
            </form>
          </div>
          <div class="menu-right pull-right">
            <app-settings></app-settings>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row ">
      <div class="col-lg-12">
        <div class="main-nav-center">
          <app-menu></app-menu>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header end -->
<app-login-modal #login></app-login-modal>