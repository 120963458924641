import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  data = []
  mainCategoryId=""
  addressCalled = false
  private messageSource = new BehaviorSubject(this.data);
  currentMessage = this.messageSource.asObservable();
  private categgorySource = new BehaviorSubject(this.mainCategoryId)
  currentCategory = this.categgorySource.asObservable()

  private cartSource = new BehaviorSubject(this.data);
  currentCart = this.cartSource.asObservable();


  private newAddress = new BehaviorSubject(this.addressCalled);
  newAddressAdded = this.newAddress.asObservable();
  constructor() { }

  changeMessage(message: any) {
    this.messageSource.next(message)
  }
  changeCateggory(category){
    this.categgorySource.next(category)
  }
  changeCartItem(cartQuntity: any) {
    this.cartSource.next(cartQuntity)
  }
  addressAddedSuccess(status){
     this.newAddress.next(status)
  }
}
