<!--section start-->
<div class="container cate-cc">
        <div class="row ">
            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 mar-2" [ngClass]="{'card':kudlaFeature===false}" *ngFor="let category of allCategory">
                <div class="collection-block" [routerLink]="['/product/category',category._id]"
                [queryParams]="{ category: category.name}">
                    <img src="{{imageUrl}}/{{category?.image}}" class="img-fluid cat-image" alt="">
                    <div class="collection-content">
                        <h4 [routerLink]="['/product/category',category._id]" [queryParams]="{ category: category.name}">{{category.name |titlecase}}</h4>
                    </div>
                </div>
            </div>

        </div>
</div>

<!--Section ends here-->