import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { BrandSlider } from '../../data/slider';

@Component({
  selector: 'app-top-brand',
  templateUrl: './top-brand.component.html',
  styleUrls: ['./top-brand.component.scss']
})
export class TopBrandComponent implements OnInit {
   @Input() brand
   imageUrl = environment.img

  constructor() { 
  }
  public BrandSliderConfig: any = BrandSlider;

  ngOnInit(): void {
    // console.log(this.brand)

  }

  
}
