import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ResponseModel } from '../../classes/response.model';
import { MainService } from '../../services/main.service';
import { NavService } from '../../services/nav.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  allCategory
  allCategoryDataInComponenet$
  constructor(public productService: ProductService, private mainService:MainService) { 
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
    this.allCategoryDataInComponenet$=this.mainService.allCategoryData$   
    this.getAllCategory()
  }

  get filterbyCategory() {
    const category = [...new Set(this.products.map(product => product.type))]
    return category
  }
  getAllCategory(){
    this.allCategoryDataInComponenet$.subscribe((res:ResponseModel)=>{
      console.log(res.data)
      this.allCategory = res.data
    })
  }

  
}
