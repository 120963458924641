import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  hahaMartFeature=environment.hahaMartFeature
  themeLogo
  constructor() {

   }

  ngOnInit(): void {
    this.themeLogo = localStorage.getItem('logo')
  }

}
