import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { LoginModalComponent } from '../../components/modal/login-modal/login-modal.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header-four',
  templateUrl: './header-four.component.html',
  styleUrls: ['./header-four.component.scss']
})
export class HeaderFourComponent implements OnInit {

  @Input() class: string = 'header-2 header-6';
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  hahaMartFeature=environment.hahaMartFeature
  public stick: boolean = false;
  isFavirote = false
  @ViewChild("login") login: LoginModalComponent;



  constructor( public authService:AuthService) { }

  ngOnInit(): void {
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth  > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }
  toggleSumMenu(){
    this.isFavirote = !this.isFavirote
  }
  openlLogin(){
    this.login.openModal()
  }

}
