import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { ProductService } from "../../../services/product.service";
import { MyProduct } from 'src/app/shared/classes/myproduct';
import { Product } from 'src/app/shared/classes/product';
import { environment } from 'src/environments/environment.prod';
import { LoginModalComponent } from '../../modal/login-modal/login-modal.component';
import { DataService } from 'src/app/shared/services/data.service';
import { ResponseModel } from 'src/app/shared/classes/response.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { nextTick } from 'process';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product:any;
  // @Input() product:Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
  @ViewChild("login") login: LoginModalComponent;
  rate=1
  max=1;
  public ImageSrc : string
  imageUrl = environment.img
  public counter: number = 1;
  onlyProduct
  selectedAttributeId
  imageArray :any[] =[];
  percentDiscount:any;
  token
  updateCart
  urbanFeature=environment.urbanFeature
  kudlaFeature=environment.kudlaFeature
  buddy_Firms=environment.buddy_Firms
  sagarFeature=environment.sagarFeature
  selectedVarientId
  productIndex:number=0;
  selectedVarient :any
  discnt: any;
  productIndex1: any;
  constructor(public productService: ProductService, private dataService:DataService,
    public authService:AuthService,private toaster:ToastrService) {

   }

  ngOnInit(): void {
    let varientWithStock = this.product.varients.filter(data => data.stock != 0)
    console.log("raj is stock", varientWithStock.length);
    
    if(varientWithStock.length>1){
      this.productIndex =  this.product.varients.findIndex(data=>data==varientWithStock[0])
    }
    this.selectedVarient = this.product.varients[this.productIndex].attributes[0].option.value
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    // console.log(this.product)
    // console.log(this.product.varients[0]._id)
    // this.ImagePushArray()
    this.discountCalculation(this.product.varients[this.productIndex].price, this.product.varients[this.productIndex].sellingPrice)
    console.log("this is var", this.productIndex);
  }

  singleVarient(data:any,ev:any) {
   let selectetdVarient :any = ev.target.value;
   console.log((data.product.varients))
  //  console.log(data.product.varients[0].attributes[0].option.value)
  console.log("these are varients",this.product.varients[this.productIndex].length)
   let varientArrayIndex:any = data.product.varients.findIndex((elem : any) => elem.attributes[0].option.value == selectetdVarient)
  //  console.log("********-------"
    this.productIndex = varientArrayIndex
    // this.productIndex1 = this.productIndex.length
      console.log("this is var1", selectetdVarient.length);
    
  console.log("these are varients1",data.product.varients[this.productIndex].price)
  }

  discountCalculation(cp,sp){
    this.percentDiscount = ((cp - sp) / cp) * 100
    console.log("this is total discounr", this.percentDiscount);
    
   return this.percentDiscount
   //console.log("discount percent", this.percentDiscount)
  }

  // Get Product Color
  Color(variants) {
    // console.log(variants)
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].attribute.name) === -1 && variants[i].attribute.name) {
        uniqColor.push(variants[i].attribute.name)
      }
      // console.log(uniqColor)
    }
    return uniqColor
  }

  ImagePushArray(){
    this.imageArray.push(
      // this.product.product.images.primary,
      // this.product.product.images.secondary,
      // this.product.product.images.image1, 
      // this.product.product.images.image2,
      )
      console.log(this.imageArray)
  }
  attribute(variants){

  }
  // Change Variants
  ChangeVariants(color, attrt) {
    // console.log(attrt)
    // console.log(color)
    // attrt.map((item) => {
    //   if (item.attribute.name === color) {
    //     attrt.images.map((img) => {
    //       if (img.image_id === item.image_id) {
    //         this.ImageSrc = img.src;
    //       }
    //     })
    //   }
    // })
  }

changeVarients(color, product){
  product.varients.map((item) => {
    if (item.color === color) {
      product.images.map((img) => {
        if (img.image_id === item.image_id) {
          this.ImageSrc = img.src;
        }
      })
    }
  })
}
  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
    // console.log(src)
  }

  addToCart(product: any) {
    this.token = localStorage.getItem('token')
    if(this.token){
      console.log(product)
      const data = {
        product: product.varients[this.productIndex]._id, quantity:  1
      }
      console.log(data)
      this.productService.addtoOrignalCart(data).subscribe((res:ResponseModel)=>{
      console.log(res.data)
      this.updateCart = res.data
      product.varients[this.productIndex].inCart = res.data
      product.varients[this.productIndex].inCart = 1
      this.dataService.changeCartItem(this.updateCart)  
      })
    }else{
      this.login.openModal()
    } this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.token = localStorage.getItem('token')
    if(this.token){
      console.log(product)
      const data = {
        product: product.varients[this.productIndex]._id
      }
      console.log(data)
      this.productService.addWishList(data).subscribe((res:ResponseModel)=>{
      console.log(res.data)
      this.updateCart = res.data
      this.dataService.changeCartItem(this.updateCart)  
      })
    }else{
      this.login.openModal()
    } this.productService.addToCart(product);
  }

  // quantitty(product){
  //   if(this.counter > product?.varients[this.productIndex]?.stock) {}
  // }

  increaseQuantity(product){
    if(product?.varients[this.productIndex]?.inCart<product?.varients[this.productIndex]?.stock){ 
      let newQunatity =  ++product.varients[this.productIndex].inCart
      console.log('Increse quantity = ', newQunatity)
      let selectedProductId=product.varients[this.productIndex]._id
      this.UpdateQuantity(selectedProductId, newQunatity)
    }else{
      this.toaster.error("Out of stock")
    }
  }

  decreaseQuantity(product){
    {
      if (this.counter > 1) this.counter-- ;
    }
     let newQunatity :number
    // console.log('Increse quantity = ', newQunatity)
    this.selectedVarientId=product.varients[this.productIndex]._id
    //const updateQuantity = --product.varients[0].inCart
    if (product.varients[this.productIndex].inCart > 1) {
      newQunatity =  --product.varients[this.productIndex].inCart
      this.UpdateQuantity(this.selectedVarientId, newQunatity)
    } 
    // else if(product.varients[0].inCart===1) {
    //   product.varients[0].inCart = 1
    //   this.UpdateQuantity(this.selectedVarientId, newQunatity)
    // }
    else if(product.varients[0].inCart===1){
      product.varients[0].inCart = 0
      this.deleteFromCart()
    }
  }

  UpdateQuantity (id, qty) {
    let data = {
      quantity:qty
    }
      this.productService.manageCartQuantity(id,data).subscribe((res: any) => {
      console.log(res.data)
    })};
  
  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
  deleteFromCart() {
    this.productService.removeFromCart(this.selectedVarientId).subscribe((res: ResponseModel) => {
      console.log(res.data);
      this.dataService.changeCartItem(res.data)
      //return this.toaster.warning('Item remove from your cart');
    }, (error: HttpErrorResponse) => {
      console.log(error);
      this.toaster.error(error.error.message, 'Error');
    });
  }
}
