import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Product } from "../../../classes/product";
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/shared/services/main.service';
import { ResponseModel } from 'src/app/shared/classes/response.model';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  
  @Input() product: Product;
  aboutUs
  @ViewChild("sizeChart", { static: false }) SizeChart: TemplateRef<any>;
  sendOtpEnable = false;
  mobile_number:string;
  otp:string;
  defaultUrl=""
  public closeResult: string;
  public modalOpen: boolean = false;
  imageUrl = environment.img
  title
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal,
  private authService: AuthService, private toaster: ToastrService, private router: Router,
   private mainService:MainService) { }

  ngOnInit(): void {
    this.title = localStorage.getItem('companyName')
   // console.log(this.title)

  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.SizeChart, { 
        size: 'lg',
        ariaLabelledBy: 'size-modal',
        centered: true,
        windowClass: 'SizeChart' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }

}

sendOtp(){
  this.sendOtpEnable =  !this.sendOtpEnable
  let data = {
    mobile_number: this.mobile_number
  }
  this.authService.mobileLogin(data).subscribe((data) => {
    console.log(data)
    this.toaster.info('OTP Send on Your Mobile Number', 'Successfully');
  }, (error: HttpErrorResponse) => {
    console.log(error);
    this.toaster.error(error.error.message, 'Error');
  });
}
sendVarifyOtp(){
  this.authService.verifyOtp(this.mobile_number, this.otp).subscribe((data) => {
    this.toaster.success('Logged In Successfully', 'Logged In');
    // this.router.navigateByUrl(this.defaultUrl).then(() => {
      window.location.reload();
    // });
  }, (error: HttpErrorResponse) => {
    console.log(error);
    this.toaster.error(error.error.message, 'Error');
  });
}

}
