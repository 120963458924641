<!-- 
Front-End Design
Ui Design
Website: www.cupcom.com.br
-->


<header>
  <!-- contact content -->
  <div class="header-content-top">
    <div class="content">

      <span><i class="fas fa-phone-square-alt" *ngIf="aboutUs"></i> <a style="color: aliceblue;" [attr.href]="callNumber"> {{aboutUs1}}</a></span>
      <span><i class="fas fa-envelope-square" *ngIf="aboutUs"></i> <a style="color: aliceblue;" [attr.href]="mailto" >{{aboutUs?.email}} </a></span>
    </div>
  </div>
  <!-- / contact content -->
  <div class="container">

    <!-- logo -->
    <strong class="logo" [ngClass]="{'size-logo':hahaMartFeature}">
        <a *ngIf="themeLogo" [routerLink]="['/']">
            <img [src]="themeLogo" class="img-fluid" [ngClass]="{'logo-image-size':summitFeature}" alt="logo">
          </a>
    </strong>
    <!-- <div class="brand-logo layout2-logo">
        <a *ngIf="themeLogo" [routerLink]="['/']">
          <img [src]="themeLogo" class="img-fluid" alt="logo">
        </a>
      </div> -->
    <!-- open nav mobile -->

    <!--search -->
    <label class="open-search" for="open-search">
      <i class="fas fa-search"></i>
            <input class="input-open-search" id="open-search" type="checkbox" name="menu" />
    <div class="search" >
      <button class="button-search"><i class="fas fa-search"></i></button>
      <button  class="button-search-mic" (click)="startVoiceRecognition()"> <i class="fas fa-microphone"></i></button>
      <input type="text" [placeholder]="placeholder" class="input-search"
      [(ngModel)]="productName"
        (ngModelChange)="productSearchParams()"/>

    </div>
 
    </label>
    <!-- // search -->
    <nav class="nav-content">
      <!-- nav -->
      <ul class="nav-content-list">
        <li class="nav-content-item account-login" *ngIf="authService?.isAuthenticated()">
          <label class="open-menu-login-account" for="open-menu-login-account">

            <input class="input-menu" id="open-menu-login-account" type="checkbox" name="menu" />

            <i class="fas fa-user-circle"></i>
            <span class="login-text" *ngIf="!authService?.isAuthenticated()"
            (click)="openlLogin()"> <strong>Login or Sign Up</strong></span> <span class="item-arrow"></span>

            <!-- submenu -->
            <ul class="login-list" *ngIf="authService?.isAuthenticated()">
              <li class="login-list-item" [routerLink]="['/pages/dashboard']">
                <a [routerLink]="['/pages/dashboard']" href="javascript:void(0)"
                routerLinkActive="active" 
                  [routerLinkActiveOptions]="{exact: true}">
                    Dashboard
                </a>
            </li>
            <li class="login-list-item" [routerLink]="['/product/order-list']">
                <a [routerLink]="['/product/order-list']" href="javascript:void(0)">
                    Orders
                </a>
            </li>
            <!-- <li class="login-list-item" routerLink="/pages/aboutus">
                <a href="javascript:void(0)" routerLink="/pages/aboutus"> About Us </a>

            </li> -->
            <li class="login-list-item" routerLink="/product/wishlist">
                <a href="javascript:void(0)" routerLink="/product/wishlist"> Wishlist </a>

            </li>
            <li class="login-list-item" (click)="authService?.signOut()">
                <a (click)="authService?.signOut()" href="javascript:void(0)">
                    Logout
                </a>
            </li>
        
            </ul>
        </label>
        </li>
        <li class="nav-content-item account-login">
          <label class="open-menu-login-account" for="open-menu-login-account"
          (click)="openlLogin()"
          *ngIf="!authService?.isAuthenticated()">
          <i class="fas fa-user-circle"></i>
            <span class="login-text" 
            > <strong>Login or Sign Up</strong></span> <span class="item-arrow"></span>

          
        </label>
        </li>
        <!-- <li class="nav-content-item">
            <a class="nav-content-link" href="https://www.cupcom.com.br/"><i class="fas fa-heart"></i></a>
        </li> -->
        <li class="nav-content-item" *ngIf="authService?.isAuthenticated()">
            <a class="nav-content-link" [routerLink]="['/product/cart']" href="javascript:void(0)">
                <i class="fas fa-shopping-cart"></i>
            </a>
            <span class="cart_qty_cls">{{ products?.length }}</span>

        </li>
        <!-- call to action -->
      </ul>
    </nav>
  </div>
  <div class="search-box"
  *ngIf="searchProduct.length>0">
    <ul class="list-group" >
      <li class="list-group-item" *ngFor="let product of searchProduct|slice:0:10"
      (click)="gotoProductpage(product?.varients[0]?._id)">
        {{product?.product?.name}}
      </li>
      <!-- <li class="list-group-item">Dapibus ac facilisis in</li>
      <li class="list-group-item">Morbi leo risus</li>
      <li class="list-group-item">Porta ac consectetur ac</li>
      <li class="list-group-item">Vestibulum at eros</li> -->
    </ul>
  </div>
  <!-- nav navigation commerce -->
  <div class="nav-container">
    <nav class="all-category-nav">
      <label class="open-menu-all" for="open-menu-all">
        <!-- <input class="input-menu-all" id="open-menu-all" type="checkbox" name="menu-open"  /> -->
        <span class="all-navigator"#toggleButton  (click)='isFavirote1 =!isFavirote1'><i class="fas fa-bars"></i>
          <span>All Category</span> 
        <i  class="fas fa-angle-down"></i>
          <i  class="fas fa-angle-up"></i>
        </span>

        <ul class="all-category-list" [ngClass]="{'show':isFavirote1}">
          <li class="all-category-list-item"  *ngFor="let cat of allCategory; let i = index"
          (mouseleave)="getAllSubCategory(cat._id)"
          >
              <a (click)='isFavirote1 =!isFavirote1'  class="all-category-list-link" href="javascript:void(0)" 
               [routerLink]="['/product/category', cat._id]"
              [queryParams]="{ category: cat.name}" >
            {{cat.name}}   
            <i class="fas fa-angle-right" *ngIf="allCategory[i]?.subcategory?.length"></i>
            </a>

            <div class="category-second-list" *ngIf="allCategory[i]?.subcategory?.length">
              <ul class="category-second-list-ul">
                <li class="category-second-item" 
                *ngFor="let subcat of allSubcategory|slice:0:5">
                  <a  href="javascript:void(0)"
                      [routerLink]="['/product/category', subcat._id]"
                      [queryParams]="{ category: subcat.name}">{{subcat.name}} </a>
                </li>
                <!-- <li class="category-second-item"><a href="https://www.cupcom.com.br/">Galaxy Note 10</a></li>
                <li class="category-second-item"><a href="https://www.cupcom.com.br/">Motorola One </a></li>
                <li class="category-second-item"><a href="https://www.cupcom.com.br/">Galaxy A80 </a></li>
                <li class="category-second-item"><a href="https://www.cupcom.com.br/">Galaxy M </a></li>
                <li class="category-second-item"><a href="https://www.cupcom.com.br/">Huaway P30 </a></li>
               -->
              </ul>

              <!-- <div class="img-product-menu"><img src="https://i.ibb.co/Vvndkmy/banner.jpg"></div> -->
              </div>
            </li>
          <!-- <li class="all-category-list-item"><a  class="all-category-list-link">Furniture <i class="fas fa-angle-right"></i></a></li>
          <li class="all-category-list-item"><a  class="all-category-list-link">Toys<i class="fas fa-angle-right"></i></a></li>
          <li class="all-category-list-item"><a  class="all-category-list-link">Computing<i class="fas fa-angle-right"></i></a></li>
          <li class="all-category-list-item"><a  class="all-category-list-link">Games<i class="fas fa-angle-right"></i></a></li>
          <li class="all-category-list-item"><a href="" class="all-category-list-link">Automotive<i class="fas fa-angle-right"></i></a></li> -->

        </ul>
        <!-- Origin Menu -->
        <!-- <ul class="all-category-list">
            <li class="all-category-list-item"><a href="https://www.cupcom.com.br/" class="all-category-list-link">Smartphones
              <i class="fas fa-angle-right"></i>
            </a>
              <div class="category-second-list">
                <ul class="category-second-list-ul">
                  <li class="category-second-item"><a href="https://www.cupcom.com.br/">Iphone 10</a></li>
                  <li class="category-second-item"><a href="https://www.cupcom.com.br/">Galaxy Note 10</a></li>
                  <li class="category-second-item"><a href="https://www.cupcom.com.br/">Motorola One </a></li>
                  <li class="category-second-item"><a href="https://www.cupcom.com.br/">Galaxy A80 </a></li>
                  <li class="category-second-item"><a href="https://www.cupcom.com.br/">Galaxy M </a></li>
                  <li class="category-second-item"><a href="https://www.cupcom.com.br/">Huaway P30 </a></li>
                </ul>
  
                <div class="img-product-menu"><img src="https://i.ibb.co/Vvndkmy/banner.jpg"></div>
                </div>
            </li>
            <li class="all-category-list-item"><a href="https://www.cupcom.com.br/" class="all-category-list-link">Furniture <i class="fas fa-angle-right"></i></a></li>
            <li class="all-category-list-item"><a href="https://www.cupcom.com.br/" class="all-category-list-link">Toys<i class="fas fa-angle-right"></i></a></li>
            <li class="all-category-list-item"><a href="https://www.cupcom.com.br/" class="all-category-list-link">Computing<i class="fas fa-angle-right"></i></a></li>
            <li class="all-category-list-item"><a href="https://www.cupcom.com.br/" class="all-category-list-link">Games<i class="fas fa-angle-right"></i></a></li>
            <li class="all-category-list-item"><a href="" class="all-category-list-link">Automotive<i class="fas fa-angle-right"></i></a></li>
  
          </ul> -->
      
        </label>

    </nav>
    <nav class="featured-category">
      <ul class="nav-row">
        <ng-container *ngIf="summitFeature">
        <li class="nav-row-list" *ngFor="let cat of allCategory|slice:0:5">
            <a class="all-category-list-link"  [routerLink]="['/product/category', cat._id]"
            [queryParams]="{ category: cat.name}" 
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          {{cat.name}}   </a>
        </li>
      </ng-container>
        <!-- <ng-container *ngIf="summitFeature">
          <li class="nav-row-list summit-nav" >
            <a href="javascript:void(0)" class="all-category-list-link" [routerLink]="['/home']"
               routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact: true}">Home </a>
        </li>
        <li class="nav-row-list" *ngIf="authService?.isAuthenticated()">
          <a [routerLink]="['/pages/dashboard']" href="javascript:void(0)" 
          class="all-category-list-link"
          routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact: true}" >
              Dashboard
          </a>
      </li>
      <li class="nav-row-list" *ngIf="authService?.isAuthenticated()">
          <a [routerLink]="['/product/order-list']" href="javascript:void(0)"
           class="all-category-list-link" 
           routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact: true}">
              Orders
          </a>
      </li>
      <li class="nav-row-list" >
          <a class="all-category-list-link"  
          href="javascript:void(0)" routerLink="/pages/aboutus"
          routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact: true}"> About Us </a>

      </li>
      <li class="nav-row-list" *ngIf="authService?.isAuthenticated()">
          <a (click)="authService?.signOut()" href="javascript:void(0)"
          class="all-category-list-link" 
          routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact: true}">
              Logout
          </a>
      </li>
      <li class="nav-row-list" *ngIf="!authService?.isAuthenticated()"
      >
        <a (click)="openlLogin()" href="javascript:void(0)"
        class="all-category-list-link" >
            Login
        </a>
    </li>
        </ng-container> -->
      
        <!-- <li class="nav-row-list"><a href="https://www.cupcom.com.br/" class="nav-row-list-link">furniture</a></li>
        <li class="nav-row-list"><a href="https://www.cupcom.com.br/" class="nav-row-list-link">Toys</a></li>
        <li class="nav-row-list"><a href="https://www.cupcom.com.br/" class="nav-row-list-link">Computing</a></li>
        <li class="nav-row-list"><a href="https://www.cupcom.com.br/" class="nav-row-list-link">Games</a></li>
        <li class="nav-row-list"><a href="https://www.cupcom.com.br/" class="nav-row-list-link">Automotive</a></li>
       -->
    </ul>
    </nav>
  </div>
</header>
<app-login-modal #login></app-login-modal>



<!-- <div *ngIf="voiceActiveSectionDisabled; else voicesection;">
  <button type="button" (click)="startVoiceRecognition()">Record</button>
</div>
<ng-template #voicesection>
  <ng-container *ngIf="!voiceActiveSectionError; else failure">
    <ng-container *ngIf="voiceText; else start">
        <span>{{voiceText}}</span>
    </ng-container>
    <ng-template #start>
      <ng-container
        *ngIf="voiceActiveSectionListening; else beginning">
        <span>Listening...</span>
      </ng-container>
      <ng-template #beginning>
        <span>Start talking...</span>
      </ng-template>
    </ng-template>
  </ng-container>
  <ng-template #failure>
    <span>Didn't catch that</span>
  </ng-template>
  <div>
    <button (click)="closeVoiceRecognition()">Close</button>
    <button (click)="startVoiceRecognition()">Restart</button>
  </div>
</ng-template> -->

