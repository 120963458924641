<!-- tap to top -->
<div class="tap-top top-cls" (click)="tapToTop()" 
	[ngStyle]="{ 'display': show ? 'block' : 'none' }">
    <div>
      <i class="fa fa-angle-double-up"></i>
    </div>
</div>
<!-- tap to top -->

<div class="tap-whatsapp whatsapp-cls" *ngIf="!kudlaFeature">
      <ng-container *ngIf="callFeature">
      <ng-container *ngIf="callNumber">
      <a [attr.href]="callNumber">
      <i class="fa fa-phone" aria-hidden="true"></i>
    </a>
    </ng-container>
    <a [attr.href]="linkwhatsApp" target="_blank">
      <i class="fa fa-whatsapp" aria-hidden="true"></i>
    </a>
  </ng-container>
    </div>