<div class="container brand-container">
  <div class="row">
    <div class="col">
      <div class="slide-6 arrow">
        <owl-carousel-o [options]="BrandSliderConfig">
          <ng-template class="card" carouselSlide *ngFor="let b of brand">
            <div class="category-block mar-2">
              <a
                [routerLink]="['/product/brand', b._id]"
                [queryParams]="{ brand: b?.name }"
              >
                <div class="category-image">
                  <img
                    src="{{ imageUrl }}/{{ b?.logo }}"
                    alt=""
                    class="brand-logo-cc"
                  />
                </div>
              </a>
            </div>
            <div class="category-details">
              <a
                [routerLink]="['/product/brand', b._id]"
                [queryParams]="{ brand: b?.name }"
              >
                <h5 class="brand-name">{{ b?.name }}</h5>
              </a>
            </div>
          </ng-template>
          <!-- <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat2.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>casual shoes</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat3.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>formal shoes</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat4.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>flat</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat5.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>heels</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat6.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>boots</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat2.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>casual shoes</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img src="assets/images/icon/cat3.png" alt="" class="w-auto">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <h5>casual shoes</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template> -->
        </owl-carousel-o>
      </div>
    </div>
  </div>
</div>
