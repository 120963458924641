<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky"
[ngStyle]="{ 'background': sagarFeature ? '#dadada' : '#ffffff' }" >
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
        <div class="container">
            <div class="row">
                
                <div class="col-lg-6">
                    <div class="header-contact">
                        <ul>
                            <li *ngIf="!grannyFeature">Welcome to Our store {{aboutUs?.companyName}}</li>
                            <li *ngIf="grannyFeature">{{aboutUs?.companyName}} Organic Farm Products</li>
                            <li *ngIf="!kudlaFeature"><i class="fa fa-phone" aria-hidden="true"></i>Call Us: {{aboutUs?.mobile}}</li>

                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 text-right">
                    <ul class="header-dropdown">
                        <li class="mobile-wishlist">
                            <a ><i [routerLink]="['/product/order-list']" class="fa fa-heart" aria-hidden="true"></i>Orders</a>
                        </li>
                        <li  class="onhover-dropdown mobile-account">
                            <i [routerLink]="['/pages/dashboard']"  class="fa fa-user" aria-hidden="true"></i> 
                           
                        </li>
                        
                    </ul>
                    <ul class="destop-logo">
                        <li> 
                            <!-- <ng-container *ngIf="!kudlaFeature">
                            <ng-container *ngIf="!this.authService.isAuthenticated()">
                            <a (click)="openModal()" href="javascript:void(0)" *ngIf="!selectedDeliveryAdderss">
                            <p  id="headerlogo">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                              &nbsp;  Select Location
                            </p> 
                            
                        </a> 
                    </ng-container>
                </ng-container> -->
                    <ng-container *ngIf="kudlaFeature">
                        <a (click)="openModalPin()" href="javascript:void(0)">
                        <p *ngIf="!pincode" id="headerlogo">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                          &nbsp;  Select Location
                        </p> 
                        <p *ngIf="pincode" id="headerlogo">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                          &nbsp; {{city |titlecase}},&nbsp;{{pincode}} 
                        </p> 
                    </a> 
                  </ng-container>
                
              <ng-container >
                <a (click)="openModal()" *ngIf="!kudlaFeature" href="javascript:void(0)">
                <p *ngIf="!pincode" id="headerlogo">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                  &nbsp;  Select Location
                </p> 
                <p *ngIf="pincode" id="headerlogo">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                  &nbsp; {{city |titlecase}},&nbsp;{{pincode}} 
                </p> 
            </a> 
          </ng-container>
                    <!-- <ng-container *ngIf="this.authService.isAuthenticated()&&(!kudlaFeature)">
                        <a href="javascript:void(0)" *ngIf="selectedDeliveryAdderss">
                            <p id="headerlogo">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                              &nbsp;  {{selectedDeliveryAdderss}}
                            </p> 
                        </a>
                    </ng-container>                    -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu">
                    <div class="menu-left">
                        <!-- <app-left-menu></app-left-menu> -->
                        <div class="brand-logo">
                            <a routerLink="/home" id="headerlogo" class="destop-logo">
                                <img  src="{{imageUrl}}/{{aboutUs?.logo}}" class="img-fluid"
                                [ngClass]="{'img-fluid-sagar':sagarFeature===true}"
                                 alt="logo">
                            </a>
                            <!-- <div id="headerlogo" class="mobile-position" *ngIf="!toganiFeature">
                                <ul >
                                    <li (click)="openModal()"> 
                                        <a href="javascript:void(0)" *ngIf="(!selectedDeliveryAdderss)||(!pincode)">
                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        <p id="headerlogo">  Select Location  </p> 
                                    </a> 
                                    <a href="javascript:void(0)" *ngIf="selectedDeliveryAdderss||pincode">
                                        <p id="headerlogo"> <i class="fa fa-map-marker" aria-hidden="true"></i>
                                          &nbsp; <span *ngIf="!kudlaFeature">{{selectedDeliveryAdderss}}</span>
                                           <span *ngIf="kudlaFeature">{{pincode}}</span>
                                          </p> 
                                    </a>
                                                           
                                    </li>
                                    <li>

                                    </li>
                                </ul>

                                    
                            </div> -->
                            <div id="headerlogo" class="mobile-position" >
                                <a [routerLink]="['/home']" >
                                    <img [src]="imageUrl+'/'+aboutUs?.logo" alt="logo">
                                </a>
                            </div>
                            <!-- <div id="headerlogo" class="mobile-position" *ngIf="sagarFeature">
                                <a [routerLink]="['/home']" *ngIf="sagarFeature">
                                    <img [src]="imageUrl+'/'+aboutUs?.logo" alt="logo">
                                </a>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="absolute-logo">
                        <div class="brand-logo">
                            <a [routerLink]="['/home']">
                                <img [src]="imageUrl+'/'+aboutUs?.logo" alt="logo">
                            </a>
                        </div>
                    </div> -->
                    <div class="menu-right pull-right">
                        <div>
                            <app-menu></app-menu>
                        </div>
                        <div>
                            <app-settings></app-settings>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<app-location #location></app-location>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" *ngIf="!this.authService.isAuthenticated()">Login </h4>
        <h4 class="modal-title" id="modal-basic-title" *ngIf="this.authService.isAuthenticated()">Select Address</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <section class="login-section" *ngIf="kudlaFeature&&!this.authService.isAuthenticated()">
            <div class="container" >
                <div class="left">
                  <div class="header">
                    <h2 *ngIf="title" class="animation a1">Welcome {{title |titlecase}}</h2>
                    <h4 class="animation a2">Login to access your Orders, Cart Items and Wishlist. </h4>
                  </div>
                  <div class="form">
                    <div class="form-group">
                    <input type="text" [(ngModel)]='mobile_number' [ngModelOptions]="{standalone: true}"  id="mobile" placeholder="Mobile Number" class="form-field form-control animation a3">
                    </div>
                    <div class="form-group">
                    <input *ngIf="sendOtpEnable" type="password" class="form-field animation a4 form-control" type="text" [(ngModel)]='otp' [ngModelOptions]="{standalone: true}" id="review" placeholder="OTP">
                   </div>
                    <!-- <p class="animation a5"><a href="#">Forgot Password</a></p> -->
                    <button *ngIf="!sendOtpEnable" (click)="sendOtp()" class="animation a6">Send OTP</button>
                    <button *ngIf="sendOtpEnable" (click)="sendVarifyOtp()" class="animation a6">Verify OTP</button>
                  </div>
                </div>
                <div  class="right"></div>
              </div>
        </section>
        <section *ngIf="this.authService.isAuthenticated()">
            <div class="container">
                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="address.length>0">
                    <ngb-panel *ngFor="let add of address;let i=index">
                        <ng-template ngbPanelTitle>
                                    <span class="td-color">{{add?.city |titlecase}},&nbsp; {{add?.pincode}}</span>
                                    <!-- <button (click)="selectDefaultAddress(add)" class="address-default-btn btn-solid">Select Address</button> -->

                         
                          </ng-template>
                      <ng-template ngbPanelContent>
                        <div class="card">
                            <div class="card-body">
                                <p>{{add?.name |titlecase}}</p>
                                <p>{{add?.mobile_number}}</p>
                                <p>{{add?.addressLine1 |titlecase}} , {{add?.addressLine2
                                    |titlecase}}</p>
                                <p>{{add?.city |titlecase}}, {{add?.state |titlecase}} ,
                                    {{add?.pincode}}</p>
                                    <button (click)="selectDefaultAddress(add)" class="address-default-btn btn-solid">Select Address</button>

                                    <button (click)="deleteAddress(i)" class="address-delete-btn btn-solid">
                                        <i class="fa fa-trash"></i>
                                    </button>
                            </div>
                        </div>
                      </ng-template>
                    </ngb-panel>
                  </ngb-accordion>
                  <hr class="my-4" *ngIf="address.length>0">
                  <a *ngIf="address.length>0" class="btn btn-solid" href="javascript:void(0)" (click)="openModal()" role="button">Add More Address</a>
                  </div>
                  <div class="container" *ngIf="address.length===0">
                  <div class="jumbotron" >
                    <p class="lead">No Address added. </p>
                    <hr class="my-4">
                    <a class="btn btn-solid" href="javascript:void(0)" (click)="openModal()" role="button">Add Address</a>
                 
                </div>
                  <app-location #location></app-location>

            </div>
        </section>
      <!-- <form>
        <div class="form-group">
          <label for="dateOfBirth">Pincode</label>
          <div class="input-group">
            <input id="dateOfBirth" [(ngModel)]="pincode" class="form-control" placeholder="Enter Pincode..." name="dp" >
            <div class="input-group-append">
            </div>
          </div>
        </div>
      </form> -->
    </div>
    <!-- <div class="modal-footer">
      <button type="button" (click)="onSavePincode()" class="btn btn-solid" (click)="modal.close('Save click')">Save</button>
    </div> -->
  </ng-template>
