import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bredcum2',
  templateUrl: './bredcum2.component.html',
  styleUrls: ['./bredcum2.component.scss']
})
export class Bredcum2Component implements OnInit {

 
  @Input() title : string;
  @Input() breadcrumb : any;

  constructor() {
  }

  ngOnInit() : void { 
    console.log(this.breadcrumb)
   }

}
