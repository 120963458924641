<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="page-title">
            <h2>{{ title }}</h2>
          </div>
        </div>
        <div class="col-sm-6">
          <nav aria-label="breadcrumb" class="theme-breadcrumb">
            <ol class="breadcrumb" *ngIf="breadcrumb.length>0">
              <li class="breadcrumb-item"><a href="javascriptvoid(0)" [routerLink]="''">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page" *ngFor="let subcat of breadcrumb">
               <a href="javascriptvoid(0)" [routerLink]="['/product/',subcat._id]" >{{ subcat.name }}</a>  
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div> 