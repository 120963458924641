import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
// import { MessagePayload } from './notification-interfaces';
@Injectable({
  providedIn: 'root'
})
export class FirebaseNotificationService {

 firebaseHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization":"key="+environment.firebaseAuthorizationKey
  });
  constructor(private http:HttpClient) {
   
  }


  sendNotification(data){
  return this.http.post("https://fcm.googleapis.com/fcm/send",data,{ headers: this.firebaseHeaders })
  }
}
