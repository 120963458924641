import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';
import { ResponseModel } from '../../classes/response.model';
import { LocationComponent } from '../../components/modal/location/location.component';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MainService } from '../../services/main.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @ViewChild("location") location: LocationComponent;
  @ViewChild('content') content;

  public stick: boolean = false;
  aboutUs
  imageUrl = environment.img
 address:any[] = [];
 selectedDeliveryAdderss 
 selectedAddressId
 pincode
 kudlaFeature=environment.kudlaFeature
 toganiFeature=environment.toganiFeature
 sagarFeature=environment.sagarFeature
 grannyFeature= environment.grannyFeature
 sendOtpEnable
 mobile_number
 otp
 title
 city
 aboutUsInComponenet$
 addressDataInComponenet$
  constructor( private mainService:MainService, private productService:ProductService,
  public authService:AuthService, private modalService: NgbModal, private dataService: DataService,
  private toaster: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.aboutUsInComponenet$ = this.mainService.aboutUsData$
    this.addressDataInComponenet$ = this.productService.addressData$
    this.getAbouUs()
    if(this.authService.isAuthenticated()){
    this.getAllAddress()
    }
    this.title = localStorage.getItem('companyName')
    
    // this.pincode = localStorage.getItem('pincode')

  }
 openModal(){
  this.location.openModal()

 }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

  getAbouUs() {
    this.aboutUsInComponenet$.subscribe((res:ResponseModel) => {
      this.aboutUs = res.data;
      console.log(this.aboutUs);
    });
  }
  getAllAddress() {
    // this.addressDataInComponenet$.subscribe((res) => {
      this.productService.getAllAddressForNotReplay().subscribe((res:ResponseModel) => {
      this.address = res.data;
      if(this.address.length>0){
        this.selectedDeliveryAdderss = this.address[0].addressLine1 +", " + this.address[0].addressLine2 ;
        this.selectedAddressId = this.address[0]._id
        console.log(this.address)
        console.log(this.selectedDeliveryAdderss)
        this.pincode = localStorage.getItem('pincode')
        this.city = localStorage.getItem('city')
        if((!this.pincode)&&(!this.city)){
          localStorage.setItem('pincode', this.address[0].pincode)
          localStorage.setItem('city', this.address[0].city)
          this.pincode = this.address[0].pincode
          this.city = this.address[0].city
        }
      }
      else{
        this.openModal()
      }
    })
}
deleteAddress(i){
  this.productService.removeaAddress(this.address[i]._id).subscribe((res:ResponseModel)=>{
    console.log(res.data)
    this.getAllAddress()
  })
}
openModalPin(){
  this.modalService.open(this.content, { centered: true });
}
onSavePincode(){
console.log(this.pincode)
localStorage.setItem('pincode', this.pincode)
this.dataService.changeMessage(this.pincode)
// this.getAllCategoryByLOcation()
}
selectDefaultAddress(add){
 console.log(add)
 this.pincode = add.pincode
 this.city = add.city
 localStorage.setItem('pincode', add.pincode)
 localStorage.setItem('city', add.city)
this.dataService.changeMessage(this.pincode)
this.modalService.dismissAll(this.content);

}
sendOtp(){
  this.sendOtpEnable =  !this.sendOtpEnable
  let data = {
    mobile_number: this.mobile_number
  }
  this.authService.mobileLogin(data).subscribe((data) => {
    console.log(data)
    this.toaster.info('OTP Send on Your Mobile Number', 'Successfully');
  }, (error: HttpErrorResponse) => {
    console.log(error);
    this.toaster.error(error.error.message, 'Error');
  });
}
sendVarifyOtp(){
  this.authService.verifyOtp(this.mobile_number, this.otp).subscribe((data) => {
    this.toaster.success('Logged In Successfully', 'Logged In');
    // this.router.navigateByUrl(this.defaultUrl).then(() => {
      window.location.reload();
    // });
  }, (error: HttpErrorResponse) => {
    console.log(error);
    this.toaster.error(error.error.message, 'Error');
  });
}


}
