import { AgmMap, GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Inject, NgZone, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/shared/classes/product';
import { ResponseModel } from 'src/app/shared/classes/response.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment.prod';


declare var google: any;

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
}
@Component({
  selector: 'app-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss']
})
export class PincodeComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() product: Product;
  aboutUs
  @ViewChild("sizeChart", { static: false }) SizeChart: TemplateRef<any>;
  sendOtpEnable = false;
  mobile_number: string;
  otp: string;
  defaultUrl = ""
  public closeResult: string;
  public modalOpen: boolean = false;
  imageUrl = environment.img
  kudlaFeature = environment.kudlaFeature
  title
  pincode
  circleRadius: number = 5000; // km
  geocoder: any;
  public location: Location = {
    lat: 28.600624167782147,
    lng: 77.38421483457952,
    marker: {
      lat: 28.600624167782147,
      lng: 77.38421483457952,
      draggable: true
    },
    zoom: 10
  };

  @ViewChild("AgmMap", { static: false }) map: AgmMap;
  public checkoutForm: FormGroup;
  public address: any[] = [];
  public editing: Boolean = false

  selectedDeliveryAdderss
  enableAddress = false 
  mapStatus=false
  public allAddress: any[] = [];
  modalOption
  addressDataInComponenet$
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal,
    private zone: NgZone, private fb: FormBuilder, public productService: ProductService,
    public mapsApiLoader: MapsAPILoader,public authService:AuthService,
    private wrapper: GoogleMapsAPIWrapper, private dataService:DataService,
    private toaster:ToastrService, private router: Router) {
    this.mapsApiLoader = mapsApiLoader;
    this.zone = zone;
    this.wrapper = wrapper;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  ngOnInit(): void {
    this.addressDataInComponenet$=this.productService.addressData$

    this.location.marker.draggable = true;   
  }

 
  ngAfterViewInit(): void {
    if(localStorage.getItem("pinModel") !== 'true'&&this.kudlaFeature)
       this.openModal();
    localStorage.setItem("pinModel", 'true');
    if(!this.authService.isAuthenticated()&&this.kudlaFeature){
      this.openModalKudla();
        }
    if(this.authService.isAuthenticated()){
      this.getAllAddress()
    }
  }



  findLocation(address) {
    if (!this.geocoder) this.geocoder = new google.maps.Geocoder()
    this.geocoder.geocode({
      'address': address
    }, (results, status) => {
      console.log(results);
      if (status == google.maps.GeocoderStatus.OK) {
        for (var i = 0; i < results[0].address_components.length; i++) {
          let types = results[0].address_components[i].types
          console.log(types);
          if (types.indexOf('locality') != -1) {
            this.location.address_level_2 = results[0].address_components[i].long_name
          }
          if (types.indexOf('country') != -1) {
            this.location.address_country = results[0].address_components[i].long_name
          }
          if (types.indexOf('postal_code') != -1) {
            this.location.address_zip = results[0].address_components[i].long_name
          }
          if (types.indexOf('administrative_area_level_1') != -1) {
            this.location.address_state = results[0].address_components[i].long_name
          }
        }
        if (results[0].geometry.location) {
          this.location.lat = results[0].geometry.location.lat();
          this.location.lng = results[0].geometry.location.lng();
          this.location.marker.lat = results[0].geometry.location.lat();
          this.location.marker.lng = results[0].geometry.location.lng();
          this.location.marker.draggable = true;
          this.location.viewport = results[0].geometry.viewport;
        }

        this.map.triggerResize()
      } else {
        alert("Sorry, this search produced no results.");
      }
    })
  }

  findAddressByCoordinates() {
    this.geocoder.geocode({
      'location': {
        lat: this.location.marker.lat,
        lng: this.location.marker.lng
      }
    }, (results, status) => {
      this.decomposeAddressComponents(results);
    })
  }

  decomposeAddressComponents(addressArray) {
    if (addressArray.length == 0) return false;
    let address = addressArray[0].address_components;
    console.log(address);
    for (let element of address) {
      console.log(element);
      if (element.length == 0 && !element['types']) continue
      if (element['types'].indexOf('street_number') > -1) {
        this.location.address_level_1 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('route') > -1) {
        this.location.address_level_1 += ', ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('locality') > -1) {
        this.location.address_level_2 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('administrative_area_level_1') > -1) {
        this.location.address_state = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('country') > -1) {
        this.location.address_country = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('postal_code') > -1) {
        this.location.address_zip = element['long_name'];
        continue;
      }
    }
    console.log(this.location);

  }

  updateOnMap() {
    console.log('updating')
    let full_address: string = this.location.address_level_1 || ""
    if (this.location.address_level_2) full_address = full_address + " " + this.location.address_level_2
    if (this.location.address_state) full_address = full_address + " " + this.location.address_state
    if (this.location.address_country) full_address = full_address + " " + this.location.address_country
    this.findLocation(full_address);
    console.log(full_address);
  }
  onSavePincode(){
    console.log(this.pincode)
    localStorage.setItem('pincode', this.pincode)
     this.dataService.changeMessage(this.pincode)
    //  this.getAllCategoryByLOcation()
    }
  circleRadiusInKm() {
    return this.circleRadius / 1000;
  }

  milesToRadius(value) {
    this.circleRadius = value / 0.00062137;
  }

  circleRadiusInMiles() {
    return this.circleRadius * 0.00062137;
  }

  markerDragEnd(m: any, $event: any) {
    this.location.marker.lat = m.coords.lat;
    this.location.marker.lng = m.coords.lng;
    this.findAddressByCoordinates();
  }


  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.SizeChart, {
        size: 'md',
        ariaLabelledBy: 'size-modal',
        centered: true,
        windowClass: 'SizeChart',
        backdrop : 'static',
        keyboard : false
      },).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  openModalKudla() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.SizeChart, {
        size: 'md',
        ariaLabelledBy: 'size-modal',
        centered: true,
        windowClass: 'SizeChart',
        backdrop : 'static',
        keyboard : false
      },).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  toggleMapStatus(){
    this.mapStatus = !this.mapStatus
  }

  sendOtp(){
    this.sendOtpEnable =  !this.sendOtpEnable
    let data = {
      mobile_number: this.mobile_number
    }
    this.authService.mobileLogin(data).subscribe((data) => {
      console.log(data)
      this.toaster.info('OTP Send on Your Mobile Number', 'Successfully');
    }, (error: HttpErrorResponse) => {
      console.log(error);
      this.toaster.error(error.error.message, 'Error');
    });
  }
  sendVarifyOtp(){
    this.authService.verifyOtp(this.mobile_number, this.otp).subscribe((data) => {
      this.toaster.success('Logged In Successfully', 'Logged In');
      this.modalService.dismissAll()
      this.router.navigateByUrl(this.defaultUrl).then(() => {
        window.location.reload();
      });
    }, (error: HttpErrorResponse) => {
      console.log(error);
      this.toaster.error(error.error.message, 'Error');
    });
  }
  getAllAddress(){
    this.addressDataInComponenet$.subscribe((res)=>{
    this.allAddress = res
    console.log(this.allAddress)
    })
  }
}
