import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay, shareReplay, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { environment } from 'src/environments/environment.prod';
import { TokenStorage } from './token.service';
import { AuthService } from './auth.service';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public Currency = { name: "Rupee", currency: "INR", price: 0 }; // Default Currency
  public OpenCart: boolean = false;
  public Products;
  dataCart;
  baseUrl = environment.apiUrl;
  headers = new HttpHeaders({
    "Content-Type": "application/json",
    token: this.token.getToken(),
  });
  addressData$;
  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
    private authService: AuthService,
    private token: TokenStorage
  ) {
    this.getAllAddress();
  }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  private get products(): Observable<Product[]> {
    this.Products = this.http
      .get<Product[]>("assets/data/products.json")
      .pipe(map((data) => data));
    // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    return (this.Products = this.Products.pipe(
      startWith(JSON.parse(localStorage["products"] || "[]"))
    ));
  }

  searchProduct(query) {
    return this.http.get(this.baseUrl + "/product/varients/find/" + query);
  }

  getProductByPage(id) {
    return this.http.get(
      this.baseUrl + "/product/varients/bycategorypage/" + id
    );
  }
  getProductsByCategory(id) {
    if (this.authService.isAuthenticated()) {
      return this.http.get(
        this.baseUrl + "/product/varients/bycategorypage/" + id,
        { headers: this.headers }
      );
    } else {
      return this.http.get(
        this.baseUrl + "/product/varients/bycategorypage/" + id
      );
    }
  }
  getProductByVarientId(id) {
    if (this.authService.isAuthenticated()) {
      return this.http.get(this.baseUrl + "/product/varients/bylink/" + id, {
        headers: this.headers,
      });
    } else {
      return this.http.get(this.baseUrl + "/product/varients/bylink/" + id);
    }
  }
  getProductsByBrand(id) {
    if (this.authService.isAuthenticated()) {
      return this.http.get(this.baseUrl + "/product/varients/bybrand/" + id, {
        headers: this.headers,
      });
    } else {
      return this.http.get(this.baseUrl + "/product/varients/bybrand/" + id);
    }
  }
  getAllSubCategory() {
    return this.http.get(this.baseUrl + "/product/category/all");
  }

  getAllCategorysub(id) {
    return this.http.get(this.baseUrl + "/product/category/subcat/app/" + id);
  }
  getCategoryBanner(id) {
    return this.http.get(this.baseUrl + "/banner/category/" + id);
  }
  getAllCategory(): Observable<any> {
    return this.http.get(this.baseUrl + "/product/category/all");
  }
  getProductById(id) {
    if (this.authService.isAuthenticated()) {
      return this.http.get(this.baseUrl + "/product/products/id/" + id, {
        headers: this.headers,
      });
    } else {
      return this.http.get(this.baseUrl + "/product/products/id/" + id);
    }
  }
  getAllCategoryandSubCategory(id): Observable<any> {
    return this.http.get(this.baseUrl + "/product/category/idupward/" + id);
  }
  getProductDetail(id) {
    if (this.authService.isAuthenticated()) {
      return this.http.get(this.baseUrl + "/product/varients/byid/" + id, {
        headers: this.headers,
      });
    } else {
      return this.http.get(this.baseUrl + "/product/varients/byid/" + id);
    }
  }

  getRelatedProduct(product) {
    if (this.authService.isAuthenticated()) {
      //return this.http.get(this.baseUrl +'/product/varients/related', product, {headers:this.headers});
    } else {
      return this.http.get(this.baseUrl + "/product/varients/related", product);
    }
  }

  getTopDiscountedProduct() {
    if (this.authService.isAuthenticated()) {
      return this.http.get(this.baseUrl + "/product/varients/topdiscounted");
    } else {
      return this.http.get(this.baseUrl + "/product/varients/topdiscounted");
    }
  }
  addtoOrignalCart(data) {
    return this.http.post(this.baseUrl + "/cart/", data, {
      headers: this.headers,
    });
  }
  getCartItem() {
    return this.http.get(this.baseUrl + "/cart", { headers: this.headers });
  }
  getCartItemByPincode(pincode) {
    return this.http.get(this.baseUrl + "/cart" + pincode, {
      headers: this.headers,
    });
  }
  manageCartQuantity(id, data) {
    return this.http.put(this.baseUrl + "/cart/" + id, data, {
      headers: this.headers,
    });
  }

  removeFromCart(id) {
    return this.http.delete(this.baseUrl + "/cart/" + "/" + id, {
      headers: this.headers,
    });
  }

  getAllAddress() {
    if (this.authService.isAuthenticated()) {
      this.addressData$ = this.http
        .get(this.baseUrl + "/address", { headers: this.headers })
        .pipe(
          tap(() => console.log("Http request ")),
          map((res: any) => Object.values(res.data)),
          shareReplay()
        );
    }
  }
  getAllAddressForNotReplay() {
    if (this.authService.isAuthenticated()) {
      return (this.addressData$ = this.http.get(this.baseUrl + "/address", {
        headers: this.headers,
      }));
    }
  }

  addAddress(address) {
    return this.http.post(this.baseUrl + "/address", address, {
      headers: this.headers,
    });
  }
  updateAddress(id, data) {
    return this.http.put(this.baseUrl + "/address/id/" + id, data, {
      headers: this.headers,
    });
  }
  removeaAddress(id) {
    return this.http.delete(this.baseUrl + "/address/" + id, {
      headers: this.headers,
    });
  }

  placeOrder(data) {
    return this.http.post(this.baseUrl + "/cart/placeorder?client=Web", data, {
      headers: this.headers,
    });
  }
  placeOrderCCavenue(data) {
    return this.http.post(
      this.baseUrl + "/cart/placeorderccavenue?client=Web",
      data,
      { headers: this.headers }
    );
  }
  createbooking(data) {
    return this.http.post(this.baseUrl + "/cart/createbooking", data, {
      headers: this.headers,
    });
  }
  razarPayVerify(data) {
    return this.http.post(this.baseUrl + "/payment/verify", data, {
      headers: this.headers,
    });
  }
  getAllOrders() {
    return this.http.get(this.baseUrl + "/corder", { headers: this.headers });
  }
  getOrdersById(id) {
    return this.http.get(this.baseUrl + "/corder/id/" + id, {
      headers: this.headers,
    });
  }
  cancelOrderProduct(data) {
    return this.http.post(this.baseUrl + "/corder/action", data, {
      headers: this.headers,
    });
  }
  cancelOrder(id, data) {
    return this.http.put(this.baseUrl + "/corder/status/" + id, data, {
      headers: this.headers,
    });
  }

  getWishList() {
    return this.http.get(this.baseUrl + "/orders/wishlist", {
      headers: this.headers,
    });
  }
  addWishList(data) {
    return this.http.post(this.baseUrl + "/orders/wishlist", data, {
      headers: this.headers,
    });
  }

  deleteWishlistItem(id) {
    return this.http.delete(this.baseUrl + `/orders/wishlist/${id}`, {
      headers: this.headers,
    });
  }

  getCoupon() {
    return this.http.get(this.baseUrl + "/discount", { headers: this.headers });
  }
  applyCoupon(data) {
    return this.http.post(this.baseUrl + "/cart/applycoupon", data, {
      headers: this.headers,
    });
  }
  createSubscriptrion(data) {
    return this.http.post(this.baseUrl + "/subscription/create", data, {
      headers: this.headers,
    });
  }
  getSubscriptrion() {
    return this.http.get(this.baseUrl + "/subscription", {
      headers: this.headers,
    });
  }
  getConfig() {
    return this.http.get(this.baseUrl + "/config");
  }

  getShipRocket(query) {
    if (this.authService.isAuthenticated()) {
      return this.http.get(this.baseUrl + "/cart/dc" + query, {
        headers: this.headers,
      });
    }
  }

  getShippingMethod(pincode) {
    if (this.authService.isAuthenticated()) {
      return this.http.get(this.baseUrl + "/shippingmethod/pincode/" + pincode);
    }
  }
  findProduct(data) {
    return this.http.get(
      this.baseUrl + "/product/varients/find/?query=" + data
    );
  }
  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(
      map((items) => {
        return items.find((item: any) => {
          return item.title.replace(" ", "-") === slug;
        });
      })
    );
  }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find((item) => item.id === product.id);
    if (!wishlistItem) {
      state.wishlist.push({
        ...product,
      });
    }
    this.toastrService.success("Product has been added in wishlist.");
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true;
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true;
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find((item) => item.id === product.id);
    if (!compareItem) {
      state.compare.push({
        ...product,
      });
    }
    this.toastrService.success("Product has been added in compare.");
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Cart
  public addToCart(product): any {
    // console.log(product)

    // console.log(state.cart)
    const cartItem = state.cart.find((item) => item._id === product._id);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    if (!stock) return false;

    if (cartItem) {
      cartItem.quantity += qty;
    } else {
      state.cart.push({
        ...product,
        quantity: qty,
      });
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));

    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(
    product: Product,
    quantity: number
  ): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.name === product.name) {
        const qty = state.cart[index].quantity + quantity;
        const stock = this.calculateStockCounts(state.cart[index], quantity);
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty;
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true;
      }
    });
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity;
    const stock = product.stock;
    if (stock < qty || stock == 0) {
      this.toastrService.error(
        "You can not add more items than available. In stock " +
          stock +
          " items."
      );
      return false;
    }
    return true;
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  }

  // Total amount
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let price = curr.price;
          if (curr.discount) {
            price = curr.price - (curr.price * curr.discount) / 100;
          }
          return (prev + price * curr.quantity) * this.Currency.price;
        }, 0);
      })
    );
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(
      map((product) =>
        product.filter((item: Product) => {
          if (!filter.length) return true;
          const Tags = filter.some((prev) => {
            // Match Tags
            if (item.tags) {
              if (item.tags.includes(prev)) {
                return prev;
              }
            }
          });
          return Tags;
        })
      )
    );
  }

  // Sorting Filter
  public sortProducts(products: any, payload: string): any {
    // console.log(products)
    if (payload === "ascending") {
      return products.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "a-z") {
      return products.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "z-a") {
      return products.sort((a, b) => {
        if (a.name > b.name) {
          return -1;
        } else if (a.name < b.name) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "low") {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "high") {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      });
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 20
  ) {
    console.log(totalItems, currentPage, pageSize);
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
