<div class="product-wrapper position-relative">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="this.percentDiscount >= 1"
        >{{
          discountCalculation(
            this.product.varients[this.productIndex].price,
            this.product.varients[this.productIndex].sellingPrice
          ) | number: "1.0-0"
        }}<i class="fa fa-percent" aria-hidden="true"></i>
      </span>
      <!-- <span class="lable3" *ngIf="!percentDiscount">0%</span> -->

      <!-- <span class="lable4" *ngIf="product?.tag?.recommended===true">recommended</span> -->
      <span
        *ngIf="product?.varients[productIndex]?.tag === 'veg'"
        class="lable4"
        ><img
          class="logo-veg"
          src="../../../../../assets/images/veg.png"
          alt=""
          srcset=""
      /></span>
      <span
        *ngIf="product?.varients[productIndex]?.tag === 'non-veg'"
        class="lable4"
        ><img
          class="logo-veg"
          src="../../../../../assets/images/non-veg.png"
          alt=""
          srcset=""
      /></span>
    </div>
    <div class="front">
      <a
        *ngIf="product?.varients[productIndex]?._id"
        [routerLink]="[
          '/product/details/',
          product?.varients[productIndex]?._id
        ]"
      >
        <img
          [src]="
            imageUrl + '/' + product?.product?.images?.primary
          "
          class="img-fluid lazy-loading"
          alt="{{ product?.product?.images?.primary }}3"
        />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a
        *ngIf="product?.varients[productIndex]?._id"
        [routerLink]="[
          '/product/details/',
          product?.varients[productIndex]?._id
        ]"
      >
        <img
          [src]="
            imageUrl + '/' + product?.product?.images?.secondary
          "
          class="img-fluid lazy-loading"
          alt="{{ product?.product?.images?.secondary }}"
        />
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" *ngFor="let image of imageArray">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image)">
          <img [lazyLoad]="imageUrl + '/' + image" />
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <!-- <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </a> -->
      <!-- <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)" >
                <i class="ti-shopping-cart"></i>
            </a> -->
      <!-- <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
                <i class="ti-heart" aria-hidden="true"></i>
            </a> -->
      <!-- <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
                <i class="ti-search" aria-hidden="true"></i>
            </a> -->
      <!-- <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
                <i class="ti-reload" aria-hidden="true"></i>
            </a> -->
    </div>
  </div>
  <div class="product-detail">
    <div>
      <a
        class="d-lg-show"
        href="javascript:void(0)"
        *ngIf="product?.varients[0]?._id"
        [routerLink]="['/product/details/', product?.varients[0]?._id]"
      >
        <h6 *ngIf="!urbanFeature" class="product-name">
          {{ product?.product.name | slice: 0:18 }}
          <span *ngIf="product?.product.name?.length > 18">...</span>
        </h6>
        <h6 *ngIf="urbanFeature" class="product-name">
          {{ product?.product.name }}
        </h6>
      </a>
      <a
        class="d-sm-show"
        href="javascript:void(0)"
        *ngIf="product?.varients[0]?._id"
        [routerLink]="['/product/details/', product?.varients[0]?._id]"
      >
        <h6 *ngIf="!urbanFeature" class="product-name">
          {{ product?.product.name | slice: 0:18 }}
          <span *ngIf="product?.product.name?.length > 18">...</span>
        </h6>
        <h6 *ngIf="urbanFeature" class="product-name">
          {{ product?.product.name }}
        </h6>
      </a>
      <a href="javascript:void(0)">
        <h6 class="brand-name" *ngIf="product?.product?.brand">
          by {{ product?.product?.brand?.name }}
        </h6>
        <!-- <h6 *ngIf="!product?.product?.brand"><b><i>By</i>  : N.A</b></h6>           -->
      </a>

      <h5
        class="avalibility-instock"
        *ngIf="counter <= product?.varients[productIndex]?.stock"
      >
        <span>In Stock</span>
      </h5>
      <h5
        class="avalibility-outofStock"
        *ngIf="counter > product?.varients[productIndex]?.stock"
      >
        <span>Out of Stock</span>
      </h5>
      <div></div>

      <select
        *ngIf="!sagarFeature"
        style="float: right"
        [(ngModel)]="selectedVarient"
        (change)="singleVarient(this, $event)"
      >
        <!-- <option selected disabled>Weight</option> -->
        <option *ngFor="let status of product?.varients">
          {{ status?.attributes[0]?.option?.value }}
        </option>
      </select>

      <!-- <h5>Out of stock</h5> -->
      <!-- <a href="javascript:void(0)" *ngIf="!sagarFeature">
            <span class="custom-rating">
                <span style="float: right;" *ngFor="let att of product?.varients[0]?.attributes">
                    <b class="black-color"><span>{{att?.option?.value |uppercase}}
                    </span>
                   </b>
                </span>
            </span>
        </a> -->

      <h5>
        <span [ngClass]="{ 'td-color': urbanFeature === true }"
          >₹{{
            product?.varients[productIndex]?.sellingPrice | number: "1.0-0"
          }}</span
        >
        <del
          [ngClass]="{
            'd-none':
              product?.varients[productIndex]?.price ===
              product?.varients[productIndex]?.sellingPrice
          }"
          *ngIf="product?.varients[productIndex]?.price"
          ><span class="money"
            >₹{{
              product?.varients[productIndex]?.price | number: "1.0-0"
            }}</span
          ></del
        >
      </h5>
      <h5 *ngIf="urbanFeature" class="saved-position">
        <span
          class="green-color saved-price"
          *ngIf="
            product?.varients[productIndex]?.price !=
            product?.varients[productIndex]?.sellingPrice
          "
          >You Saved:₹
          {{
            product?.varients[productIndex]?.price -
              product?.varients[productIndex]?.sellingPrice | number: "1.0-0"
          }}</span
        >
      </h5>

      <!-- <h4> {{product?.varients[0]?.sellingPrice| currency: productService.Currency?.currency:'symbol'}}
                <del *ngIf="product?.discount">{{ product?.price | currency: productService.Currency?.currency:'symbol' }}</del>
                <span *ngIf="product?.discount">{{ product?.discount | number:'1.0-0'}}% off</span>
            </h4> -->

      <!-- <p>{{ product?.product?.details }}</p> -->

      <!-- <bar-rating class="custom-rating" rate="0" [readOnly]="true"></bar-rating> -->
      <div class="d-flex postion-div align-items-center">
        <bar-rating
          *ngIf="!urbanFeature && !kudlaFeature"
          class="custom-rating"
          rate="{{ rate }}"
          max="{{ max }}"
          [readOnly]="true"
        >
        </bar-rating>
        <span *ngIf="!urbanFeature && !kudlaFeature"
          >({{ product?.varients[productIndex]?.reviews?.total }})</span
        >
        <ng-container *ngIf="kudlaFeature && product?.varients.length > 1">
          <a
            [routerLink]="[
              '/product/details/',
              product?.varients[productIndex]?._id
            ]"
            [ngClass]="{ 'cart-btn': urbanFeature === false }"
            href="javascript:void(0)"
            >Open</a
          >
        </ng-container>
        <ng-container *ngIf="kudlaFeature && product?.varients.length === 1">
          <a
            [ngClass]="{ 'cart-btn': urbanFeature === false }"
            *ngIf="!this.authService.isAuthenticated()"
            (click)="addToCart(product)"
            href="javascript:void(0)"
            >Add</a
          >
          <a
            [ngClass]="{ 'cart-btn': urbanFeature === false }"
            *ngIf="
              this.authService.isAuthenticated() &&
              !product?.varients[productIndex]?.inCart
            "
            (click)="addToCart(product)"
            href="javascript:void(0)"
            >Add</a
          >
        </ng-container>

        <!-- <ng-container *ngIf="product?.varients[0]?.subscribable">
            <a  [ngClass]="{'subscribe-btn':buddy_Firms===true }" *ngIf="!this.authService.isAuthenticated()" (click)="addToCart(product)" href="javascript:void(0)">Subscribe</a>
            <a  [ngClass]="{'subscribe-btn':buddy_Firms===true }" *ngIf="this.authService.isAuthenticated()&&(!product?.varients[0]?.inCart)" (click)="addToCart(product)" href="javascript:void(0)">Subscribe</a>
        </ng-container> -->

        <ng-container *ngIf="!kudlaFeature">
          <a
            [ngClass]="{
              'urban-cart': urbanFeature === true,
              'cart-btn': urbanFeature === false
            }"
            *ngIf="!this.authService.isAuthenticated()"
            (click)="addToCart(product)"
            class="btn cart-bottom-btn br-cart"
            [class.disabled]="counter > product?.varients[productIndex]?.stock"
            href="javascript:void(0)"
            >Add</a
          >

          <a
            [ngClass]="{
              'urban-cart': urbanFeature === true,
              'cart-btn': urbanFeature === false
            }"
            *ngIf="
              this.authService.isAuthenticated() &&
              !product?.varients[productIndex]?.inCart
            "
            (click)="addToCart(product)"
            class="btn cart-bottom-btn br-cart"
            [class.disabled]="counter > product?.varients[productIndex]?.stock"
            href="javascript:void(0)"
            >Add</a
          >
        </ng-container>

        <a
          *ngIf="
            this.authService.isAuthenticated() &&
            product?.varients[productIndex]?.inCart
          "
          class="new-cart-btn"
          href="javascript:void(0)"
        >
          <i
            (click)="decreaseQuantity(product)"
            class="quntity-icon fa fa-minus"
            aria-hidden="true"
          ></i>

          <span class="quntity-text">{{
            product?.varients[productIndex]?.inCart
          }}</span>

          <i
            (click)="increaseQuantity(product)"
            class="quntity-icon fa fa-plus"
            aria-hidden="true"
          ></i>
        </a>
      </div>

      <!-- <ul class="color-variant" *ngIf="Color(product?.attributes?.length)">
                <li [class]="color" *ngFor="let color of product?.attributes" (click)="Color(product?.attributes)" (click)="ChangeVariants(color.attribute?.name, product?.attributes)">
                    <span *ngFor="let optio of color?.option">
                    <span [ngStyle]="{'background-color': optio?.value}"> </span> {{optio?.value}}
                    </span>
                </li>
            </ul> -->
    </div>
  </div>
</div>
<!-- <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box> -->
<app-quick-view
  #quickView
  [product]="product"
  [currency]="currency"
></app-quick-view>
<app-cart-modal
  #cartModal
  [product]="product"
  [currency]="currency"
  *ngIf="cartModal"
></app-cart-modal>
<app-login-modal #login></app-login-modal>
