import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseModel } from 'src/app/shared/classes/response.model';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-subscribe-product',
  templateUrl: './subscribe-product.component.html',
  styleUrls: ['./subscribe-product.component.scss']
})
export class SubscribeProductComponent implements OnInit,OnDestroy {
  @Input() selctedVarientProduct: any;
  @Input()  selectedProductName

  allAddress:any[]=[]
  public checkoutForm:  FormGroup;
  subscribeForm:FormGroup
  enableAddress=false
  selectAddress=false
  showAddressList=false
  editing=false
  selectedAddressId
  address
  addressIndex
  selectedAddress
  uplaoding=false
  sun:number=0
  mon:number=0
  tue:number=0
  wed:number=0
  thr:number=0
  fri:number=0
  sat:number=0
  @ViewChild("subscribeModal", { static: false }) SubscribeModal: TemplateRef<any>;
  public modalOpen: boolean = false;
  addressDataInComponenet$
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private router: Router, private modalService: NgbModal,public productService: ProductService,private fb:FormBuilder) { 
    
    
    
    console.log(this.selctedVarientProduct)
    console.log("constructor log")

  }
  public closeResult: string;

  ngOnInit(): void {
    this.addressDataInComponenet$ = this.productService.addressData$
    this.initAddressForm()
    this.initSubscribeForm()
    this.getAllAddress()
    console.log(this.selctedVarientProduct)
    console.log("ng log")

  }
  findProduct(){
    console.log(this.selctedVarientProduct)
    console.log("ng log") 
    this.getAllAddress()
  }
  showAddress(){
    this.showAddressList = !this.showAddressList
  }
  toggleAddress(i){
    this.selectedAddress = this.allAddress[i]
    this.selectedAddressId =this.selectedAddress._id
    this.showAddressList = false
  }

  initSubscribeForm() {
    this.subscribeForm = this.fb.group({
      // name: ['', Validators.required],
      // pincodes: ['', Validators.required],
      days: this.fb.group({
        sun:[0, [Validators.required, Validators.pattern('[0-9]+')]],
        mon:[0, [Validators.required, Validators.pattern('[0-9]+')]],
        tue:[0, [Validators.required, Validators.pattern('[0-9]+')]],
        wed:[0, [Validators.required, Validators.pattern('[0-9]+')]],
        thu:[0, [Validators.required, Validators.pattern('[0-9]+')]],
        fri:[0, [Validators.required, Validators.pattern('[0-9]+')]],
        sat:[0, [Validators.required, Validators.pattern('[0-9]+')]]
      })

    });
  }
  initAddressForm(){
    this.checkoutForm = this.fb.group({
       name : ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
       mobile_number : ['', [Validators.required, Validators.pattern('[0-9]+')]],
       addressLine1 : ['', Validators.required],
       addressLine2 : [''],
       city : ['', Validators.required],
       state : ['', Validators.required],
       pincode : ['', Validators.required],
    })
  }
  onSubmit(){
    console.log(this.subscribeForm.value)
    let subscriptionData = {
      days : {
        sun:this.sun,
        mon:this.mon,
        tue:this.tue,
        wed:this.wed,
        thu:this.thr,
        fri:this.fri,
        sat:this.sat
      },
      address: this.selectedAddressId,
      product:this.selctedVarientProduct[0]._id
    }
    console.log(subscriptionData)
    this.productService.createSubscriptrion(subscriptionData).subscribe((res:ResponseModel)=>{
      console.log(res.data)
      this.modalService.dismissAll();
    })
  }
  openSuscribeModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.SubscribeModal, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getAllAddress(){
    this.addressDataInComponenet$.subscribe((res)=>{
    this.allAddress = res
    console.log(this.allAddress)
    if(this.allAddress.length>0){
      this.selectedAddress=this.allAddress[0]
      this.selectedAddressId =this.selectedAddress._id
    }
    })
  }
  submitAddress(){
    //console.log(this.checkoutForm.value)
    //console.log(this.checkoutForm.value);
    if (this.editing) {
      this.updateAddres(this.checkoutForm.value);
    } else {
      this.addAddress(this.checkoutForm.value);
    }
  }
  addAddress(address: any){
    this.productService.addAddress(address).subscribe((res:ResponseModel)=>{
      //console.log(res.data)
      this.enableAddress = false
      this.getAllAddress()
      this.checkoutForm.reset()
    })
  }
  updateAddres(address: any){
    this.productService.updateAddress(this.selectedAddressId,address).subscribe((res:ResponseModel)=>{
      //console.log(res.data)
      this.editing = false
      this.enableAddress = false
      this.getAllAddress()
      this.checkoutForm.reset()
    })
  }
  deleteAddress(i){
    if(confirm("Are you sure want to delete this address...?"))
    this.productService.removeaAddress(this.address[i]._id).subscribe((res:ResponseModel)=>{
      console.log(res.data)
      this.getAllAddress()
    })
  }
  editAddres(i){
    // this.openModal()
    this.editing = true;
    this.enableAddress = true
    this.addressIndex = i
    this.selectedAddress = this.address[i]
    this.selectedAddressId = this.selectedAddress._id;
    this.setAddresForm(this.selectedAddress)
    }
    setAddresForm(selectedAddress){
      this.checkoutForm.controls['name'].setValue(selectedAddress.name);
      this.checkoutForm.controls['mobile_number'].setValue(selectedAddress.mobile_number);
      this.checkoutForm.controls['addressLine1'].setValue(selectedAddress.addressLine1);
      this.checkoutForm.controls['addressLine2'].setValue(selectedAddress.addressLine2);
      this.checkoutForm.controls['city'].setValue(selectedAddress.city);
      this.checkoutForm.controls['state'].setValue(selectedAddress.state);
      this.checkoutForm.controls['pincode'].setValue(selectedAddress.pincode);
    }

    ngOnDestroy() {
      if(this.modalOpen){
        this.modalService.dismissAll();
      }
    }

   sundayIncrease(){
    this.sun = isNaN(this.sun) ? 0 : this.sun;
    this.sun++;
    console.log(this.sun)
   }
   sundayDecrease(){
    this.sun = isNaN(this.sun ) ? 0 : this.sun ;
    this.sun  < 1 ? this.sun  = 1 : '';
    this.sun --;
    console.log(this.sun)

   }

   mondayIncrease(){
    this.mon = isNaN(this.mon) ? 0 : this.mon;
    this.mon++;
    console.log(this.mon)
   }
   mondayDecrease(){
    this.mon = isNaN(this.mon ) ? 0 : this.mon ;
    this.mon  < 1 ? this.mon  = 1 : '';
    this.mon --;
    console.log(this.mon)

   }

   tuesdayIncrease(){
    this.tue = isNaN(this.tue) ? 0 : this.tue;
    this.tue++;
    console.log(this.tue)
   }
   tuesdayDecrease(){
    this.tue = isNaN(this.tue ) ? 0 : this.tue ;
    this.tue  < 1 ? this.tue  = 1 : '';
    this.tue --;
    console.log(this.tue)

   }

   wednesdayIncrease(){
    this.wed = isNaN(this.wed) ? 0 : this.wed;
    this.wed++;
    console.log(this.wed)
   }
   wednesdayDecrease(){
    this.wed = isNaN(this.wed ) ? 0 : this.wed ;
    this.wed  < 1 ? this.wed  = 1 : '';
    this.wed --;
    console.log(this.wed)

   }

   thrusdayIncrease(){
    this.thr = isNaN(this.thr) ? 0 : this.thr;
    this.thr++;
    console.log(this.thr)
   }
   thrusdayDecrease(){
    this.thr = isNaN(this.thr ) ? 0 : this.thr ;
    this.thr  < 1 ? this.thr  = 1 : '';
    this.thr --;
    console.log(this.thr)

   }
   fridayIncrease(){
    this.fri = isNaN(this.fri) ? 0 : this.fri;
    this.fri++;
    console.log(this.fri)
   }
   fridayDecrease(){
    this.fri = isNaN(this.fri ) ? 0 : this.fri ;
    this.fri  < 1 ? this.fri  = 1 : '';
    this.fri --;
    console.log(this.fri)

   }
   saturdayIncrease(){
    this.sat = isNaN(this.sat) ? 0 : this.sat;
    this.sat++;
    console.log(this.sat)
   }
   saturdayDecrease(){
    this.sat = isNaN(this.sat ) ? 0 : this.sat ;
    this.sat  < 1 ? this.sat  = 1 : '';
    this.sat --;
    console.log(this.sun)

   }
}


