import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from 'src/app/shared/classes/response.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment.prod';
import { CartModalComponent } from '../../modal/cart-modal/cart-modal.component';
import { LoginModalComponent } from '../../modal/login-modal/login-modal.component';
import { QuickViewComponent } from '../../modal/quick-view/quick-view.component';


@Component({
  selector: 'app-product-box-two',
  templateUrl: './product-box-two.component.html',
  styleUrls: ['./product-box-two.component.scss']
})
export class ProductBoxTwoComponent implements OnInit {

  @Input() product:any;
  // @Input() product:Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
  @ViewChild("login") login: LoginModalComponent;
  rate=1
  max=1;
  public ImageSrc : string
  imageUrl = environment.img
  imageArray :any[] =[];
  percentDiscount:any;
  token
  updateCart

  selectedVarientId
  constructor(private productService: ProductService, private dataService:DataService,
    public authService:AuthService,private toaster:ToastrService) {
    // console.log(this.product)

   }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    // console.log(this.product)
    // console.log(this.product.varients[0]._id)
    // this.ImagePushArray()
    this.discountCalculation(this.product.varients[0].price,this.product.varients[0].sellingPrice)
  }

  discountCalculation(cp,sp){
   this.percentDiscount =((cp-sp)/cp)*100
   //console.log("discount percent", this.percentDiscount)
  }

  // Get Product Color
  Color(variants) {
    // console.log(variants)
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].attribute.name) === -1 && variants[i].attribute.name) {
        uniqColor.push(variants[i].attribute.name)
      }
      // console.log(uniqColor)
    }
    return uniqColor
  }

  ImagePushArray(){
    this.imageArray.push(
      // this.product.product.images.primary,
      // this.product.product.images.secondary,
      // this.product.product.images.image1, 
      // this.product.product.images.image2,
      )
      console.log(this.imageArray)
  }
  attribute(variants){

  }
  // Change Variants
  ChangeVariants(color, attrt) {
    // console.log(attrt)
    // console.log(color)
    // attrt.map((item) => {
    //   if (item.attribute.name === color) {
    //     attrt.images.map((img) => {
    //       if (img.image_id === item.image_id) {
    //         this.ImageSrc = img.src;
    //       }
    //     })
    //   }
    // })
  }

changeVarients(color, product){
  product.varients.map((item) => {
    if (item.color === color) {
      product.images.map((img) => {
        if (img.image_id === item.image_id) {
          this.ImageSrc = img.src;
        }
      })
    }
  })
}
  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
    // console.log(src)
  }

  addToCart(product: any) {
    this.token = localStorage.getItem('token')
    console.log(product.varients[0].inCart)
    if(this.token){
      // console.log(product)
      const data = {
        product: product.varients[0]._id, quantity:  1
      }
      console.log(data)
      this.productService.addtoOrignalCart(data).subscribe((res:ResponseModel)=>{
      // console.log(res.data)
      this.updateCart = res.data
      //product.varients[0].inCart = res.data
      this.product.varients[0].inCart = 1
      // console.log(product.varients[0].inCart)
      this.dataService.changeCartItem(this.updateCart)  
      })
    }else{
      this.login.openModal()
    } this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.token = localStorage.getItem('token')
    if(this.token){
      console.log(product)
      const data = {
        product: product.varients[0]._id
      }
      console.log(data)
      this.productService.addWishList(data).subscribe((res:ResponseModel)=>{
      console.log(res.data)
      this.updateCart = res.data
      this.dataService.changeCartItem(this.updateCart)  
      })
    }else{
      this.login.openModal()
    } this.productService.addToCart(product);
  }

  increaseQuantity(product){
    let newQunatity =  ++product.varients[0].inCart
    console.log('Increse quantity = ', newQunatity)
    let selectedProductId=product.varients[0]._id
    this.UpdateQuantity(selectedProductId, newQunatity)
  }

  decreaseQuantity(product){
     let newQunatity :number
    // console.log('Increse quantity = ', newQunatity)
    this.selectedVarientId=product.varients[0]._id
    //const updateQuantity = --product.varients[0].inCart
    if (product.varients[0].inCart > 1) {
      newQunatity =  --product.varients[0].inCart
      this.UpdateQuantity(this.selectedVarientId, newQunatity)
    } 
    // else if(product.varients[0].inCart===1) {
    //   product.varients[0].inCart = 1
    //   this.UpdateQuantity(this.selectedVarientId, newQunatity)
    // }
    else if(product.varients[0].inCart===1){
      product.varients[0].inCart = 0
      this.deleteFromCart()
    }
  }

  UpdateQuantity (id, qty) {
    let data = {
      quantity:qty
    }
      this.productService.manageCartQuantity(id,data).subscribe((res: any) => {
      console.log(res.data)
    })};
  
  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
  deleteFromCart() {
    this.productService.removeFromCart(this.selectedVarientId).subscribe((res: ResponseModel) => {
      console.log(res.data);
      this.dataService.changeCartItem(res.data)
      //return this.toaster.warning('Item remove from your cart');
    }, (error: HttpErrorResponse) => {
      console.log(error);
      this.toaster.error(error.error.message, 'Error');
    });
  }


 
}
