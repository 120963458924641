<div *ngIf="!loader"   >
    <div class="img-wrapper">
        <div class="lable-block">
            <span class="lable3" *ngIf="percentDiscount">{{percentDiscount| number: '1.0-0'}}%</span>
            <!-- <span class="lable3" *ngIf="!percentDiscount">0%</span> -->

            <span class="lable4" *ngIf="product?.product?.recommended===true">recommended</span>
        </div>
        <div class="front">
            <a *ngIf="product?.varients[0]?._id" [routerLink]="['/product/details/', product?.varients[0]?._id]">
                <img [src]="imageUrl+'/'+product?.product?.images?.primary" class="img-fluid lazy-loading"
                    alt="{{  product?.product?.images?.primary }}" />
            </a>
        </div>
        <div class="back" *ngIf="onHowerChangeImage">
            <a *ngIf="product?.varients[0]?._id" [routerLink]="['/product/details/', product?.varients[0]?._id]">
                <img [src]="imageUrl+'/'+product?.product?.images?.secondary" class="img-fluid lazy-loading"
                    alt="{{  product?.product?.images?.secondary }}">
            </a>
        </div>
        <ul class="product-thumb-list" *ngIf="thumbnail" [routerLink]="['/product/details/', product?.varients[0]?._id]">
            <li class="grid_thumb_img" *ngFor="let image of imageArray">
                <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage( image)">
                    <img [lazyLoad]="imageUrl+'/'+image">
                </a>
            </li>
        </ul>
        <div class="cart-info cart-wrap">
            <!-- <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </a>
            <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
                <i class="ti-shopping-cart"></i>
            </a>
            <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
                <i class="ti-heart" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
                <i class="ti-search" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
                <i class="ti-reload" aria-hidden="true"></i>
            </a> -->
        </div>
    </div>
    <div class="product-detail">
        <div>
            <h5>
                {{ product?.varients[0]?.sellingPrice | currency:currency?.currency:'symbol' }}
                <del *ngIf="product?.varients[0]?.price"><span class="money"> {{ product?.varients[0]?.price | currency:currency?.currency:'symbol' }}</span></del>
            </h5>
            <a  class="d-lg-show" *ngIf="product?.varients[0]?._id" [routerLink]="['/product/details/', product?.varients[0]?._id]">
                <h6>{{ product?.product.name | titlecase| slice :0:17 }} 
                    <span *ngIf="product?.product.name?.length>17">...</span></h6>
            </a>
            <a  class="d-sm-show" *ngIf="product?.varients[0]?._id" [routerLink]="['/product/details/', product?.varients[0]?._id]">
                <h6>{{ product?.product.name | titlecase| slice :0:12 }} 
                    <span *ngIf="product?.product.name?.length>12">...</span></h6>
            </a>
            <!-- <p>{{ product?.product?.details }}</p> -->
           
            <div class="d-flex postion-div">
                <bar-rating  
                class="custom-rating" rate="{{rate}}" max="{{max}}" [readOnly]="true">
                
            </bar-rating>
            <span style="margin-top: 3px;">({{product?.varients[0]?.reviews?.total}})</span>
            <a class="cart-btn" (click)="addToCart(product)" href="javascript:void(0)">Add</a>
          </div>
        </div>
    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
<app-login-modal #login></app-login-modal>
